import React, {
  Component,
  Fragment,
  PureComponent,
  useEffect,
  useState,
} from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons";
import featuresStyles from "../../assets/jss/features/featuresPageStyles";
import {
  OrangeTypography,
  TaglineForHeadline,
} from "../../components/typography";
import Insights from "../googleAdsGrader/insights";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as types from "../../actions/actionTypes";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let id = 1;

function createFeaturesData(img, head, title, para, bg, leftAlign) {
  id += 1;
  return { id, img, head, title, para, bg, leftAlign };
}

export const featuresData = [
  createFeaturesData(
    "featuresAndBenefitsOnline",
    "The six factors that our Grader examines to calculate your score",
    "Keep a close tab on all key paid search account performance metrics from a single dashboard",
    "LXRGuide’s Performance Dashboard gives you a snapshot of your account performance for the selected time frame and summarizes the campaign tasks you completed. " +
      " The elegant dashboard contains a live KPI graph, a campaign snapshot with key metrics, and recommended tasks curated by our AI to optimize your account." +
      " <b>Contact an LXRGuide Growth Consultant at growth@lxrguide.com for a free demo.</b>",
    // 'We will give your site an on page score from 0 to 10 based on the average performance of the individual pages measured for a set of metrics.',
    false,
    false
  ),
  createFeaturesData(
    "featuresAndBenefitsTransparency",
    null,
    "30+ powerful optimization algorithms + real-time data = task recommendations tailored to drive growth",
    "Paid Search campaign success requires hours of real-time campaign analysis and performance optimization. " +
      "Winning in a competitive paid search landscape is not easy and only the best optimization strategies deliver the desired performance impact. " +
      "Our technologists partnered with our paid search experts to create a set of 30+ optimization algorithms for driving maximum performance gains. " +
      "You can execute the AI-drive task recommendations with just one click to automatically improve your campaigns. " +
      "Each task has a short description. " +
      "With LXRGuide you can now compete with your paid search competitors, and win!",
    // 'We will give your site an on page score from 0 to 10 based on the average performance of the individual pages measured for a set of metrics.',
    true,
    true
  ),
  createFeaturesData(
    "featuresAndBenefitsAdvantage",
    null,
    "Take charge of your paid search campaigns with LXRGuide's extensive Reporting Suite",
    "LXRGuide places powerful reports at your fingertips to help you take charge of your account performance.  " +
      "The reports help you uncover actionable insights regarding every aspect of your paid search campaign. " +
      "Having invested 7M+ hours in managing and scaling paid search campaigns, we know actionable insights matter!",
    // 'We will give your site an on page score from 0 to 10 based on the average performance of the individual pages measured for a set of metrics.',
    false,
    false
  ),

  // createFeaturesData('featuresAndBenefitsChanges', null,
  //     'Make changes from row 11, seat A.',
  //     'Are you constantly traveling for work? No need to stress about your campaigns. Keeping track of your PPC performance on the go is simple with LXRGuide. ' +
  //     'LXRGuide works seamlessly with your mobile phone so you can make changes anywhere, anytime. That’s less time managing a paid search campaign at your desktop and more time doing other things - like running a business.',
  //     // 'We will give your website a score from 0 to 10 based on the average speed of the individual pages of your site calculated across ' +
  //     // 'all devices.',
  //     true, true),
];

function GetMainImage({ theme, screenWidth }) {
  const isTabletResponsive = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );
  return (
    <Typography align={"center"} gutterBottom>
      {screenWidth < 900
        ? isTabletResponsive
          ? icons.features("auto")
          : icons.features("100%")
        : icons.features("auto")}
    </Typography>
  );
}

function FeaturesPage(props) {
  const { classes, width, theme } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.featuresPageTrack();
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card className={classes.card} style={{zoom:"90%"}}>
      <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
        <Helmet>
          <title>Features & Benefits | Customer Benefits | LXRGuide</title>
          <meta
            name="description"
            content="Grow your small business by using our quick and powerful paid search recommendation tool to improve performance. Features weekly recommendations to optimize campaigns."
          />
        </Helmet>
        <Grid container>
          <Grid item sm={12} xs={12} md={12}>
            <Grid container>
              <Grid
               
                item
                sm={12}
                xs={12}
                md={12}
                className={[ classes.gridBg, classes.sectionBg]}
              >
                <Grid container justifyContent={"center"}>
                  <Grid item sm={11} xs={11} md={12}>
                    <GetMainImage theme={theme} screenWidth={screenWidth} />
                  </Grid>
                </Grid>
                <br />
                <OrangeTypography
                  style={{ display: "flex", justifyContent: "center" }}
                  variant={"caption"}
                  color={"secondary"}
                  gutterBottom
                >
                  FEATURES & BENEFITS
                </OrangeTypography>
                <Typography className={classes.mainTitle} gutterBottom>
                  <b>A Game plan for Growth: Work smart.</b>
                  <br />
                  <b>Work easy. Partner with LXRGuide.</b>
                </Typography>
                <br />
                <TaglineForHeadline
                  color="textPrimary"
                  align="center"
                  className={classes.mainCaption}
                >
                  It is not easy to stay online and concentrate on paid search
                  all the time. There are so many other pressing tasks for
                  entrepreneurs and marketing managers. Let LXRGuide handle the
                  difficult and time-consuming tasks while you stay in charge.
                </TaglineForHeadline>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            {featuresData.map((data, i) => (
              <Insights
                key={i}
                leftIcon={screenWidth <= 900 ? true : data.leftAlign}
                // head={data.head}
                icon={
                  <img
                    src={icons[data.img]}
                    alt={data.title}
                    className={classes[data.img]}
                    style={{
                      boxShadow:
                        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    }}
                  />
                }
                title={data.title}
                para={data.para}
                bg={data.bg}
                features
              />
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
FeaturesPage.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  featuresPageTrack: () => dispatch({ type: types.GROWTH_MODEL_PAGE_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(featuresStyles, { withTheme: true })(FeaturesPage)));

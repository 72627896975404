import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Table, TableCell, TableRow } from "@mui/material";
import { data, initialState } from "./view-details-data";
import * as _ from "lodash";
import { connect } from "react-redux";
import ServiceDetails from "./service-details";
import PaymentDetails from "./payment-details";
import CartDetails from "./cart-details";
import CircularProgress from "@mui/material/CircularProgress";
import {
  updateItemCount,
  resetItemCount,
  getAccountStructure,
  saveOnDemandFroms,
} from "../../../src/actions/index";
import { calculateSteps } from "./templates";
import { UPDATE_Premier_PACK_MONTHLY_TOGGLE, UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE } from "../../actions/actionTypes";

export const CustomTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    border: "1px solid #E3E6F4",
    borderRadiusTop: 3,
    backgroundColor: "#F2F3F9",
    [theme.breakpoints.up('xl')]: {
      fontSize: "1rem",
    },
  },
  body: {
    fontSize: 14,
    border: "unset",
    padding: 15,
    [theme.breakpoints.up('xl')]: {
      fontSize: "1rem",
    },
  },
}))(TableCell);

export const CustomTableRow = withStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[100],
    backgroundColor: "#fff",
  },
}))(TableRow);
export const CustomTable = withStyles((theme) => ({
  root: {
    borderRadius: 3,
    border: "1px solid #E3E6F4",
  },
}))(Table);

export const formatOptions = {
  commafy: true,
  precision: 0,
  locales: "en-US",
  currency: true,
  wholenumber: "ceil",
  currencyIndicator: "USD",
  cssClass: ["inline"],
};


function CartContainer(props) {

  let itemCounts = props.itemCounts;
  let fromInputs = props.fromInputs;

  let onDemandPrices = props.accountStructureDetails.onDemandPackages;
 
  // const unitPrices = _.ServiceDetails((Values(_.keyBy(data, "name"), (v) => v.price);
  const unitPrices = _.mapValues(_.keyBy(onDemandPrices, "packageName"), (v) => v.amount);
  
  const lineTotals = _.mapValues(itemCounts, (v, k) => v * unitPrices[k]);
 

  // const result = OndemandPackageBackend(onDemandPackages);


  const grandTotal = Object.values(lineTotals).reduce((acc, value) => {
    if (!isNaN(value)) {
      return acc + value;
    }
    return acc;
  }, 0);

  const [state, setState] = useState({
    ...initialState,
    // multiPageToggle: props.initialPage,
    //   steps: createSteps(itemCounts),
  });

  const [steps, setSteps] = useState(initialState.steps);
  const [activeStep, setActiveStep] = useState(initialState.activeStep);
  const [multiPageToggle, setMultiPageToggle] = useState(props.initialPage);

  console.log("initial state", state); // we are getting the steps and activeSteps

  useEffect(() => {
    setSteps(createSteps(itemCounts));
    setActiveStep(initialState.activeStep);
    // setState({ multiPageToggle: props.initialPage });
    setMultiPageToggle(props.initialPage);
    props.getAccountStructure();
  }, []);

  const createSteps = (currentCounts) => {
    const steps = [];
    _.forEach(currentCounts, (v, k) => {
      for (var i = 1; i <= v; i++) {
        steps.push({ type: k, index: i, label: k + i });
      }
    });
    console.log("The steps in createSteps", steps);
    return steps;
  };

  const handleReset = () => {
    console.log("Reset");
    props.resetCartCount();
  };

  const handleCount = async (item) => {
    console.log("Name:", item.name);
    await props.updateItemCount(item.name, item.currentCount);
  };

  const saveCart = async () => {
    console.log("saving OnDemand Cart Gtotal:", multiPageToggle);
    const itemCounts = props.itemCounts;
    let steps = createSteps(itemCounts);
    setSteps(steps);
    setActiveStep(0);
    // await setState({ steps: steps, activeStep: 0 });
    steps = calculateSteps(steps);
    setSteps(steps);
    console.log("The steps are", steps);
    if (steps && steps.length == 0) {
      handleNext(true);
    } else {
      handleNext(false);
    }
  };

  const handleNext = async (skip) => {
    debugger
    console.log("handle Next before", multiPageToggle);
    if (skip == true) {
      console.log("handle Next if");
      await setMultiPageToggle(multiPageToggle + 2);
    } else {
      console.log("handle Next else");
      await setMultiPageToggle(multiPageToggle + 1);
    }
    console.log("handle Next after", multiPageToggle);
  };

  const handlePrevious = async () => {
    console.log("handle Previous before", multiPageToggle);
    calculateSteps(steps);
    if (steps && steps.length == 0) {
      console.log("handle Previous if");
      await setMultiPageToggle(multiPageToggle - 2);
      // setState((prevState) => ({
      //   ...prevState,
      //   multiPageToggle: state.multiPageToggle - 2,
      // }));
      // setState({ multiPageToggle: state.multiPageToggle - 2 });
    } else {
      console.log("handle Previous else");
      await setMultiPageToggle(multiPageToggle - 1);
      // setState((prevState) => ({
      //   ...prevState,
      //   multiPageToggle: state.multiPageToggle - 1,
      // }));
      // setState({ multiPageToggle: state.multiPageToggle - 1 });
    }
    console.log("handle Previous after", multiPageToggle);
  };

  const handleMultiPageAndSteppers = async (label, preNext, parentToggle) => {
    console.log("from preNext in parent:", preNext);
    console.log("label:", label);
    // const { activeStep } = state;
    if (parentToggle) handleNext();
    if (preNext != null && preNext == true) {
      setActiveStep(activeStep + 1);
      // await setState({
      //   activeStep: activeStep + 1,
      // });
    } else if (preNext != null && preNext == false) {
      setActiveStep(activeStep - 1);
      // await setState({
      //   activeStep: activeStep - 1,
      // });
    }
  };
  console.log("multiPageToggle", multiPageToggle);
  const renderCart = () =>
    multiPageToggle && multiPageToggle == 1 ? (
      <>
        <CartDetails
          grandTotal={grandTotal}
          currentTotals={lineTotals}
          currentCounts={itemCounts}
          handleReset={() => handleReset}
          handleCount={(data) => handleCount(data)}
          saveCart={() => saveCart()}
          isDrawerOpen={props.isDrawerOpen}
          userInfo={props.accountStructureDetails}

        />

      </>
    ) : null;
  const renderServiceDetails = () =>
    multiPageToggle == 2 ? (
      <ServiceDetails
        handlePreviuos={handlePrevious}
        currentCounts={itemCounts}
        parentToggle={handleNext}
        allInputs={fromInputs}
        steps={steps}
        handleMultiPageAndSteppers={(label, preNext, parentToggle) =>
          handleMultiPageAndSteppers(label, preNext, parentToggle)
        }
        activeStep={activeStep}
      />
    ) : null;
  const renderPayment = () =>
    multiPageToggle == 3 ? (
      <PaymentDetails
        handlePreviuos={handlePrevious}
        currentTotals={lineTotals}
        grandTotal={grandTotal}
        taskMode={props.initialPage == 3}
        multiPageToggle={multiPageToggle}
        steps
      />
    ) : null;
  return (
    <Grid container>
      {props.accountStructureDetails.isLoading == true ? (
        <CircularProgress color={"secondary"} />
      ) : (
        <Grid item sm={12} xs={12} md={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent={"center"}
          >
            {renderCart()}
            {renderServiceDetails()}
            {renderPayment()}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateItemCount: (name, counts) => {
    return dispatch(updateItemCount(name, counts));
  },
  resetCartCount: () => {
    return dispatch(resetItemCount());
  },
  getAccountStructure: () => {
    return dispatch(getAccountStructure());
  },
  saveInputsToServer: (miniService) => {
    return dispatch(saveOnDemandFroms(miniService));
  },
  UpdatePremierMonthly: (isMonthly) => {

    return dispatch({
      type: UPDATE_Premier_PACK_MONTHLY_TOGGLE,
      isPremierServicePackMonthly: isMonthly,
    });
  },
  UpdateSEOTrimonthly: (isTrimonthly) => {

    return dispatch({
      type: UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE,
      isSEOPackTrimonthly: isTrimonthly,
    });
  },
});
const mapStateToProps = (state) => ({
  itemCounts: state.miniServiceItemCount,
  fromInputs: state.inputFroms,
  accountStructureDetails: state.accountStructureDetails,
  selectedServicePacks: state.selectedServicePackBytTaskDataIds,
  isDrawerOpen: state.header.open,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);

//HOMEPAGE HEADER

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import {
  Button,
  Menu,
  MenuItem,
  Toolbar,
  AppBar,
  Hidden,
  SwipeableDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Popover,
  Collapse,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MenuIcon from "@mui/icons-material/Menu";
import line from "../../assets/img/wh-line.png";
import Lxrguide from "../../assets/img/wh-or-lxrguide.png";
import { Tabs, Tab } from "@mui/material";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import styles from "../../assets/jss/externalHeader/externalHeaderStyles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import icons from "../../components/icons";
import { LoginOutLineButton, SignUpButton } from "../../components/buttons";
import { Grid } from "@mui/material/index";
import Netelixirpoweredby from "../../assets/img/powerby-netelixir.png";
import { event } from "d3";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const CustomMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    margin: "10px 0px",
    "&:hover": {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main + " !important",
  },
}))(MenuItem);

const CustomTab = withStyles((theme) => ({
  labelContainer: {
    padding: "6px 10px",
  },
}))(Tab);

const solutionMenuOptions = [
  {
    name: "SOLUTION",
    pathname: "/growth-model",
    activeIndex: 0,
    selectedIndex: 1,
  },
  {
    name: "LXRGUIDE GROWTH MODEL",
    pathname: "/growth-model",
    activeIndex: 0,
    selectedIndex: 1,
  },
  {
    name: "FEATURES & BENEFITS",
    pathname: "/features",
    activeIndex: 0,
    selectedIndex: 2,
  },
];

const toolsMenuOptions = [
  { name: "FREE TOOLS", pathname: "/tools", activeIndex: 1, selectedIndex: 0 },
  {
    name: "GOOGLE ADS GRADER",
    pathname: "/google-ads-grader",
    activeIndex: 1,
    selectedIndex: 1,
  },
  // {
  //   name: "SEO SITE GRADER",
  //   pathname: "/seo-site-grader",
  //   activeIndex: 1,
  //   selectedIndex: 2,
  // },
  {
    name: "WEBSITE HEALTH SCORECARD",
    pathname: "/website-health-score-card",
    activeIndex: 1,
    selectedIndex: 3,
  },
];

const resourceMenuOptions = [
  {
    name: "RESOURCES",
    pathname: "/blog",
    activeIndex: 3,
    selectedIndex: 1,
  },
  { name: "BLOG", pathname: "/blog", activeIndex: 3, selectedIndex: 1 },
];

const CustomListItem = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    opacity: 0.5,
  },
  selected: {
    color: theme.palette.common.white,
    opacity: 1,
    borderLeft: "2px solid" + theme.palette.selected,
  },
}))(ListItemButton);

const ParentCustomListItem = withStyles((theme) => ({
  root: {
    width: "200px",
    padding: 15,
    borderRadius: 4,
    margin: "20px 0px",
    color: theme.palette.common.white,
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.05) !important",
    fontWeight: "700",
  },
  selected: {
    color: theme.palette.common.white,
    opacity: 1,
    backgroundColor: theme.palette.orangeColor + " !important",
  },
}))(ListItemButton);

function HeaderNewInterface(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [solutionAnchorEl, setSolutionAnchorEl] = useState(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [solutionOpen, setSolutionOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [tabValue, setTabValue] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSolutionIndex, setSelectedSolutionIndex] = useState(0);
  const [selectedResourcesIndex, setSelectedResourcesIndex] = useState(0);
  const [left, setLeft] = useState(false);

  const handleTabChange = (e, value) => {
    setTabValue({ value });
  };

  const handleProfileMenuOpen = (event, dropDownType) => {
    if (dropDownType === "solution") {
      setSolutionAnchorEl(event.currentTarget);
      setSolutionOpen(true);
    }
    if (dropDownType === "tools") {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
    if (dropDownType === "resources") {
      setResourcesAnchorEl(event.currentTarget);
      setResourcesOpen(true);
    }
  };

  const handleMenuToggle = (event, dropDownType) => {
    if (dropDownType == "solution") {
      setOpen(false);
      setSolutionOpen(!solutionOpen);
      setResourcesOpen(false);
    }

    if (dropDownType == "tools") {
      setOpen(!open);
      setSolutionOpen(false);
      setResourcesOpen(false);
    }
    if (dropDownType == "resources") {
      setOpen(false);
      setSolutionOpen(false);
      setResourcesOpen(!resourcesOpen);
    }
  };

  const handleMenuClose = (e, dropDownType) => {
    if (dropDownType == "solution") {
      setSolutionAnchorEl(null);
      setSolutionOpen(false);
    }
    if (dropDownType == "tools") {
      setAnchorEl(null);
      setOpen(false);
    }
    if (dropDownType == "resources") {
      setResourcesAnchorEl(null);
      setResourcesOpen(false);
    }
  };

  const handleMenuItemClicked = (e, i, dropDownType) => {
    if (dropDownType == "solution") {
      setSolutionAnchorEl(null);
      setSolutionOpen(false);
      setSelectedIndex(i);
    }
    if (dropDownType == "tools") {
      setAnchorEl(null);
      setOpen(false);
      setSelectedIndex(i);
    }
    if (dropDownType == "resources") {
      setResourcesAnchorEl(null);
      setResourcesOpen(false);
      setSelectedIndex(i);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setLeft(open);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (e) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const routes = () => {
    const routes = [
      {
        name: "SOLUTION",
        pathname: "/solution",
        activeIndex: 0,
        selectedIndex: 0,
        ariaOwns: solutionAnchorEl ? "solution-menu" : undefined,
        ariaPopUp: solutionAnchorEl ? "true" : undefined,
        onMouseOver: (event) => handleProfileMenuOpen(event, "solution"),
        onClick: (event) => handleMenuToggle(event, "solution"),
      },
      {
        name: "FREE TOOLS",
        pathname: "/tools",
        activeIndex: 1,
        selectedIndex: 0,
        ariaOwns: anchorEl ? "tools-menu" : undefined,
        ariaPopUp: anchorEl ? "true" : undefined,
        onMouseOver: (event) => handleProfileMenuOpen(event, "tools"),
        onClick: (event) => handleMenuToggle(event, "tools"),
      },
      {
        name: "PRICING",
        pathname: "/pricing",
        activeIndex: 2,
        selectedIndex: 0,
      },
      {
        name: "RESOURCES",
        pathname: "/resources",
        activeIndex: 3,
        selectedIndex: 0,
        ariaOwns: resourcesAnchorEl ? "resources-menu" : undefined,
        ariaPopUp: resourcesAnchorEl ? "true" : undefined,
        onMouseOver: (event) => handleProfileMenuOpen(event, "resources"),
        onClick: (event) => handleMenuToggle(event, "resources"),
      },
    ];
    return routes;
  };

  useEffect(() => {
    const {
      location: { pathname },
    } = props;
    [
      ...routes(),
      ...solutionMenuOptions,
      ...toolsMenuOptions,
      ...resourceMenuOptions,
    ].forEach((route) => {
      switch (pathname) {
        case `${route.pathname}`:
          if (tabValue !== route.activeIndex) {
            setTabValue(route.activeIndex);
            if (route.selectedIndex && route.selectedIndex !== selectedIndex) {
              setSelectedIndex(route.selectedIndex);
            }
          }
          break;
        default:
          break;
      }
    });
  }, [props.location.pathname, tabValue, selectedIndex]);

  const {
    classes,
    width,
    location: { pathname },
  } = props;

  const isToolsOpen = Boolean(anchorEl);
  const isSolutionOpen = Boolean(solutionAnchorEl);
  const isResourcesOpen = Boolean(resourcesAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  console.log("selectedIndex===", selectedIndex);

  const renderToolsMenu = (
    <Menu
      id="tools-menu"
      anchorEl={anchorEl}
      open={isToolsOpen}
      classes={{ paper: classes.menu }}
      onClose={(e) => handleMenuClose(e, "tools")}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      MenuListProps={{
        onMouseLeave: (e) => handleMenuClose(e, "tools"),
      }}
    >
      {toolsMenuOptions.map((data, i) => (
        <CustomMenuItem
          key={i}
          classes={{ root: classes.menuItem }}
          onClick={(e) => {
            handleMenuItemClicked(e, data.selectedIndex, "tools");
            setTabValue(1);
          }}
          component={Link}
          to={data.pathname}
          selected={i === selectedIndex && tabValue === 1}
        >
          {data.name}
        </CustomMenuItem>
      ))}
    </Menu>
  );

  const renderSolutionMenu = (
    <Menu
      id={"solution-menu"}
      anchorEl={solutionAnchorEl}
      open={isSolutionOpen}
      classes={{ paper: classes.menu }}
      onClose={(e) => handleMenuClose(e, "solution")}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      MenuListProps={{
        onMouseLeave: (e) => handleMenuClose(e, "solution"),
      }}
    >
      {solutionMenuOptions.map((data, i) => (
        <CustomMenuItem
          key={i}
          classes={{ root: classes.menuItem }}
          onClick={(e) => {
            handleMenuItemClicked(e, data.selectedIndex, "solution");
            setTabValue(0);
          }}
          component={Link}
          to={data.pathname}
          selected={i == selectedIndex && tabValue == 0}
        >
          {data.name}
        </CustomMenuItem>
      ))}
    </Menu>
  );

  const renderResourcesMenu = (
    <Menu
      id={"resources-menu"}
      anchorEl={resourcesAnchorEl}
      open={isResourcesOpen}
      classes={{ paper: classes.menu }}
      onClose={(e) => handleMenuClose(e, "resources")}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      MenuListProps={{
        onMouseLeave: (e) => handleMenuClose(e, "resources"),
      }}
    >
      {resourceMenuOptions.map((data, i) => (
        <CustomMenuItem
          key={i}
          classes={{ root: classes.menuItem }}
          onClick={(e) => {
            handleMenuItemClicked(e, data.selectedIndex, "resources");
            setTabValue(3);
          }}
          button
          component="a"
          target={"_blank"}
          href={data.pathname}
        >
          {data.name}
        </CustomMenuItem>
      ))}
    </Menu>
  );

  const LoginsActionsBtns = (classes) => {
    return (
      <Fragment>
        <a href={"login.html"} style={{ textDecoration: "unset" }}>
          <LoginOutLineButton
            variant="outlined"
            className={classes.ButtonStyle}
          >
            Login
          </LoginOutLineButton>
        </a>{" "}
        &ensp;
        <a href={"signup.html"} style={{ textDecoration: "unset" }}>
          <SignUpButton
            variant="contained"
            className={classes.SignUpLogo}
            href={"/signup.html"}
          >
            Signup
          </SignUpButton>
        </a>
      </Fragment>
    );
  };

  const sideList = (
    <div>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        className={classes.mobileMenu}
      >
        <Grid item>
          <IconButton>{icons.closeMenuMobile}</IconButton>
        </Grid>
        <Grid item>{icons.brandMobile1}</Grid>
      </Grid>
      <div className={classes.loginsbtnsMob}>
        <br />
        {LoginsActionsBtns(classes)}
        <br />
        <br />
      </div>
      <List style={{ marginLeft: 20, marginRight: 25 }}>
        {routes().map((option, index) => (
          <Fragment>
            <ParentCustomListItem
              divider
              button
              key={option.name}
              onClick={(event) => {
                if (option.onClick) option.onClick(event);
                // {
                //   setTabValue(index);
                //   setLeft(false);
                // }
                if (
                  option.name == "SOLUTION" ||
                  option.name == "RESOURCES" ||
                  option.name == "FREE TOOLS"
                ) {
                  event.stopPropagation();
                } else {
                  setTabValue(index);
                }
              }}
              selected={tabValue == index}
              component={option.name == "PRICING" ? Link : null}
              to={option.name == "PRICING" ? option.pathname : null}
            >
              <ListItemText
                classes={{ primary: classes.menuItemText }}
                primary={option.name == "FREE TOOLS" ? "TOOLS" : option.name}
              />
              {option.name == "SOLUTION" ? (
                solutionOpen ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )
              ) : null}

              {option.name == "FREE TOOLS" ? (
                open ? (
                  <ExpandLessIcon className={classes.iconColor} />
                ) : (
                  <ExpandMoreIcon className={classes.iconColor} />
                )
              ) : null}

              {option.name == "RESOURCES" ? (
                resourcesOpen ? (
                  <ExpandLessIcon className={classes.iconColor} />
                ) : (
                  <ExpandMoreIcon className={classes.iconColor} />
                )
              ) : null}
            </ParentCustomListItem>

            {option.name == "SOLUTION" ? (
              <Collapse in={solutionOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  {solutionMenuOptions.slice(1).map((option, i) => (
                    <CustomListItem
                      key={i}
                      classes={{ root: classes.menuItem }}
                      onClick={(e) => {
                        handleMenuItemClicked(e, i, "solution");
                        setTabValue(0);
                      }}
                      component={Link}
                      to={option.pathname}
                      selected={i == selectedIndex && tabValue == 0}
                    >
                      {option.name}
                    </CustomListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
            {option.name == "FREE TOOLS" ? (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div">
                  {toolsMenuOptions.map((option, i) => (
                    <CustomListItem
                      key={i}
                      classes={{ root: classes.menuItem }}
                      onClick={(e) => {
                        handleMenuItemClicked(e, i, "tools");
                        setTabValue(1);
                      }}
                      component={Link}
                      to={option.pathname}
                      selected={i == selectedIndex && tabValue == 1}
                    >
                      {option.name}
                    </CustomListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
            {option.name == "RESOURCES" ? (
              <Collapse in={resourcesOpen} timeout="auto" unmountOnExit>
                <List component="div">
                  {resourceMenuOptions.slice(1).map((option, i) => (
                    <CustomListItem
                      key={i}
                      classes={{ root: classes.menuItem }}
                      onClick={(e) => {
                        handleMenuItemClicked(e, i, "resources");
                        setTabValue(3);
                      }}
                      button
                      component="a"
                      href={option.pathname}
                    >
                      {option.name}
                    </CustomListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </Fragment>
        ))}
      </List>
    </div>
  );

  const getTabs = (tabValue, classes, routes) => {
    return (
      <Fragment>
        <Tabs
          value={tabValue}
          className={classes.tabContainer}
          indicatorColor={"secondary"}
          onChange={handleTabChange}
        >
          {routes &&
            routes.map((route) => (
              <CustomTab
                key={route.name}
                className={classes.tab}
                label={route.name}
                component={Link}
                to={route.pathname}
                aria-owns={route.ariaOwns}
                aria-haspopup={route.ariaPopUp}
                onMouseOver={route.onMouseOver}
                style={{ cursor: "pointer", color: "white" }}
              />
            ))}
        </Tabs>
        <div className={classes.loginButtons}>{LoginsActionsBtns(classes)}</div>
      </Fragment>
    );
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={classes.root} id="homepage-header">
      <AppBar position="fixed" className={classes.AppBarstyle}>
        <Toolbar className={classes.ToolBarStyle}>
          <IconButton
            className={classes.mobileMenu}
            onClick={toggleDrawer(true)}
            aria-haspopup="true"
            style={{ color: "white" }}
            size="large"
          >
            {icons.menuMobileExternal}
          </IconButton>
          <div>
            <Button
              href={"/"}
              onClick={() => {
                setTabValue(-1);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <img src={Lxrguide} className={classes.LxrGuideImagestyle} />
            </Button>
          </div>
          &nbsp;&nbsp;
          <div>
            <a>
              <img src={line} />
            </a>
          </div>
          &nbsp;&nbsp; &nbsp;
          <div>
            <a href="https://www.netelixir.com/" target="blank">
              {width == "sm" || width == "xs" ? (
                <img
                  src={Netelixirpoweredby}
                  width={"100px"}
                  alt={"Netelixirpoweredby"}
                />
              ) : (
                <img src={Netelixirpoweredby} alt={"Netelixirpoweredby"} />
              )}
            </a>
          </div>
          <Hidden lgDown>{getTabs(tabValue, classes, routes())}</Hidden>
          {renderSolutionMenu}
          {renderToolsMenu}
          {renderResourcesMenu}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#1E334E",
          },
        }}
        anchor="left"
        open={left}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={classes.mobilePaper}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {sideList}
        </div>
      </SwipeableDrawer>
      <div className={classes.toolbarMargin}></div>
    </div>
  );
}
HeaderNewInterface.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  withWidth()(withStyles(styles, { withTheme: true })(HeaderNewInterface))
);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Typography,
} from "@mui/material";
import {
  JsAddUserLabelTypography,
  JsSubSectionTypography,
} from "../../components/typography";
import icons from "../../components/icons";
import { JsTextAsButton } from "../../components/buttons";
import { JsOrangeContainedBtn } from "../../components/buttons";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useParams, useHistory } from "react-router-dom";
import {
  storeAdgroupData,
  deleteAdgroupData,
  submitCampaignFramework,
  fetchAdGroupDetailsByCampaignId,
} from "../../actions/jumpstartRevamped";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Authority } from "./commonComponents/jumpstartEnum.js";
import { websiteValidation } from "../commonFunctions/commonFunctions";
import { ErrorSmallTypography } from "../../components/typography";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
    "&.Mui-selected": {
      background: "none",
    },
    "&.MuiTreeItem-iconContainer": {
      margin: 0,
      width: 0,
    },
  },
  [`&.${treeItemClasses.root}`]: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 25,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JsAddAdgroups(props) {
  const {
    selCampaignId,
    storeAdgroupData,
    deleteAdgroupData,
    submitCampaignFramework,
    disableFramework,
    jsAccountId,
    jwtToken,
    adgroupList,
    loading,
  } = props;

  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState();

  const [test,setTest] = useState(selCampaignId);
  const [errorAdgroupURL, setErrorAdgroupURL] = useState();
  const history = useHistory();

  const [initialValue, setInitialValue] = useState({
    jsAccountId: jsAccountId,
    jsCampaignId: selCampaignId,
    name: `Ad group 1`,
    id: null,
    children: [
      {
        id: String(0  + 0 + 2),
        label: "Type Ad Group Name here",
      },
      {
        id: String(0 + 0 + 3),
        label:  "Type Focus Product or Theme here",
      },
      {
        id: String(0 + 0 + 4),
        label: "Type URL here",
      },
    ],
  })
const [adGroups, setAdGroups] = useState([]);

  useEffect(() => {
    if (selCampaignId !== "" && selCampaignId !== null) {
      setAdGroups([initialValue]);
    }
  }, [selCampaignId]);

  useEffect(() => {
    if (loading === false && adgroupList !== null && adgroupList !== undefined) {
      const newAdGroups = Object.values(adgroupList).map((adgroup, index) =>
        createNewAdGroup(index, adgroup.id, adgroup.name, adgroup.productFocus, adgroup.url)
      );
      setAdGroups(newAdGroups);
    }
  }, [loading]);

  const createNewAdGroup = (index, adgroupId, adgroupName, productFocus, url) => {
    const indexString = String(index);

    return {
      jsAccountId: jsAccountId,
      jsCampaignId: selCampaignId,
      name: `Ad group ${adGroups.length + 1}`,
      id: adgroupId,
      children: [
        {
          id: String(indexString + adGroups.length + 2),
          label: adgroupName || "Type Ad Group Name here",
        },
        {
          id: String(indexString + adGroups.length + 3),
          label: productFocus || "Type Focus Product or Theme here",
        },
        {
          id: String(indexString + adGroups.length + 4),
          label: url || "Type URL here",
        },
      ],
    };
  };

  const handleAddAdGroup = async (index, adgroupId, adgroupName, productFocus, url) => {
    const newAdGroup = createNewAdGroup(index, adgroupId, adgroupName, productFocus, url);
    setAdGroups([...adGroups, newAdGroup]);
  };

  const checkFieldsValidity = (dataArray) => {
    for (const item of dataArray) {
      if (
        item.name === "" ||
        item.name === "Type Ad Group Name here" ||
        item.productFocus === "" ||
        item.productFocus === "Type Focus Product or Theme here" ||
        item.url === "" ||
        item.url === "Type URL here" ||
        !websiteValidation(item.url)
      ) {
        return true; 
      }
    }
    return false;
  };

  //Delete dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const createLabel = (labelText, inputId, groupId) => (
    <div className="treeViewCss">
      {icons.HorizontalDashedLine}
      <input
        className="addAdGroupCss"
        value={labelText.includes("Type") ? "" : labelText}
        id={inputId}
        placeholder={labelText}
        onChange={(e) => handleInputChange(groupId, inputId, e.target.value)}
        required
        disabled={disableFramework}
      />
    </div>
  );
  
  const handleInputChange = (groupId, inputId, value) => {
    const updatedAdGroups = adGroups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          children: group.children.map((child) => {
            if (child.id === inputId) {
              return {
                ...child,
                label: value,
              };
            }
            return child;
          }),
        };
      }
      return group;
    });
    setAdGroups(updatedAdGroups);
  };
  const generateNewAdgroupList = (adGroups) => {
    return adGroups.map((group) => ({
      jsAccountId: Number(jsAccountId),
      jsCampaignId: selCampaignId,
      name:  group.children[0].label,
      id: group.id,
      productFocus: group.children[1].label,
      url:  group.children[2].label,
    }));
  };
  const handleDeleteAdGroup = async (group, index) => {
    const deletedAdgroup = generateNewAdgroupList([group]);
    if(group.id != null || group.id != undefined){
      const data = await deleteAdgroupData(jwtToken, deletedAdgroup);
      if (data.status == 200) {
        setAdGroups(adGroups.filter(a => a.id !== group.id));
        setSnackbarMsg("Successfully deleted Ad Group");
      } else {
        setError(true);
        setSnackbarMsg("Error in deleting Ad Group");
      }
      setSnackbarOpen(true);
    } else{
      setAdGroups( adGroups.filter((group, i) => i != index));
      setSnackbarMsg("Successfully deleted Ad Group");
      setError(false);
      setSnackbarOpen(true);
    }
  };

  const handleSaveButton = async () => {
    const newAdgroupList = generateNewAdgroupList(adGroups);

    if (checkFieldsValidity(newAdgroupList)) {
      setError(true);
      setSnackbarMsg("Some fields are empty, or URL format is invalid.");
    } else {
      const storedAddgroupdata = await storeAdgroupData(
        jwtToken,
        newAdgroupList
      );

      if (storedAddgroupdata.status == 200) {
        const {status, ...savedAdgroupList} = storedAddgroupdata;
        const newAdGroups = Object.values(savedAdgroupList).map((adgroup, index) =>
          createNewAdGroup(index, adgroup.id, adgroup.name, adgroup.productFocus, adgroup.url)
        );
        setAdGroups(newAdGroups);
        setSnackbarMsg("Successfully updated adgroup details");
        setError(false);
        
      } else {
        setError(true);
        setSnackbarMsg("Error in updating adgroup details");
      }
    }
    setSnackbarOpen(true);
  };

  const handleSubmitButton = async () => {
    const newAdgroupList = generateNewAdgroupList(adGroups);

    if (checkFieldsValidity(newAdgroupList)) {
      setError(true);
      setSnackbarMsg("Some fields are empty, or URL format is invalid.");
      setSnackbarOpen(true);
    } else {
      const data = await storeAdgroupData(jwtToken, newAdgroupList);  
      if (data && data.status == 200) {
        const submitData = await submitCampaignFramework(
          jwtToken,
          jsAccountId
        );
        if (submitData && submitData.status == 200) {
          history.push(`/campaign-framework-completed/${jsAccountId}`);
        } else {
          setError(true);
          setSnackbarMsg("Error in submitting campaign details");
          setSnackbarOpen(true);
        }
      } else {
        setError(true);
        setSnackbarMsg("Error in updating adgroup details");
        setSnackbarOpen(true);
      }
    }
  };

  //submit dialog box
  const handleOpenDialog = () => {
    const newAdgroupList = generateNewAdgroupList(adGroups);

    if (checkFieldsValidity(newAdgroupList)) {
      setError(true);
      setSnackbarMsg("Some fields are empty, or URL format is invalid.");
      setSnackbarOpen(true);
    } else {
      setOpenDialog(true);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <JsAddUserLabelTypography>
          <span className="numberContainer">2</span>Add the Ad Groups Name,
          Product Focus, URL details
        </JsAddUserLabelTypography>
      </Grid>
      <Grid item xs={12}>
        {loading ? <>
          <Skeleton/> <Skeleton/><Skeleton/>
        </> : <>
        
          {adGroups.length > 0 && Object.values(adGroups).map((group, index) => (
          <TreeView
            key={group.id ? group.id : group.name}
            aria-label="customized"
            defaultExpanded={[String(group.id), group.name]}
            sx={{ overflowX: "hidden", pb: 5 }}
          >
            <StyledTreeItem
              nodeId={group.id ? String(group.id) : group.name}
              label={
                <div className="flexSpaceBtwn">
                  <JsAddUserLabelTypography>
                    Ad Group {index + 1}
                  </JsAddUserLabelTypography>
                  {adGroups.length > 1 && (
                    <JsTextAsButton
                      disabled={disableFramework}
                      style={{ color: "#ED4A4A" }}
                      onClick={() => handleDeleteAdGroup(group, index)}
                    >
                      Delete
                    </JsTextAsButton>
                  )}
                </div>
              }
            >
              {group.children.map((child) => (
                <StyledTreeItem
                  key={child.id}
                  nodeId={String(child.id)}
                  // label={createLabel(child.label, child.id)}
                  label={createLabel(child.label, child.id, group.id)}
                />
              ))}
            </StyledTreeItem>
            {errorAdgroupURL == !true ? (
              <ErrorSmallTypography>
                Please enter valid URL
              </ErrorSmallTypography>
            ) : (
              ""
            )}
            {index == adGroups.length - 1 ? (
              <JsTextAsButton
                style={{ color: "F58120", float: "right" }}
                onClick={() => handleAddAdGroup(index + 1,null,null,null,null)}
                disabled={disableFramework}
              >
                + Add New Ad Group
              </JsTextAsButton>
            ) : (
              ""
            )}
          </TreeView>
        ))}
        </>}

      </Grid>
      <Grid item container xs={12} direction="row" alignItems="center">
        <Grid item xs={4}>
          {/* {authority == Authority.ANALYST || authority == Authority.RWF ? (
            ""
          ) : (
            <>
              <FormControlLabel
                control={<Checkbox size="small" id="final" color="secondary" />}
                label="Mark this as final"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    color: "#838383",
                  },
                }}
              />
            </>
          )} */}
        </Grid>
        <Grid
          item
          xs={8}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          columnSpacing={2}
        >
          {loading ? <Skeleton/> :<>
            <Grid item>
            <Button
              variant="text"
              onClick={handleSaveButton}
              disabled={disableFramework}
              style={{ fontSize: "0.875rem", textTransform: "none" }}
            >
              <SaveOutlinedIcon sx={{ color: "#F58120" }} />
              Save
            </Button>
          </Grid>
          <Grid item>
            <JsOrangeContainedBtn
              disabled={disableFramework}
              style={{ padding: "3% 5%", fontSize: "0.875rem" }}
              onClick={() => handleOpenDialog()}
            >
              Submit
            </JsOrangeContainedBtn>
          </Grid>
          </>
          }
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Are you sure you want to submit the Campaign Framework?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Disagree</Button>
          <Button onClick={() => handleSubmitButton()}>Agree</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  storeAdgroupDataReducer: state.storeAdgroupDataReducer,
  deleteAdgroupDataReducer: state.deleteAdgroupDataReducer,
  submitCampaignFrameworkReducer: state.submitCampaignFramework,
  fetchAdGroupDetailsByJsAccountIdReducer:
    state.fetchAdGroupDetailsByJsAccountIdReducer,
 fetchAdGroupDetailsByCampaignIdReducer: state.fetchAdGroupDetailsByCampaignIdReducer,

});
const mapDispatchToProps = (dispatch) => ({
  storeAdgroupData: (jwtToken, newArray) =>
    dispatch(storeAdgroupData(jwtToken, newArray)),
  deleteAdgroupData: (jwtToken, deleteArrayAdgroup) =>
    dispatch(deleteAdgroupData(jwtToken, deleteArrayAdgroup)),
  submitCampaignFramework: (jwtToken, id) =>
    dispatch(submitCampaignFramework(jwtToken, id)),
  fetchAdGroupDetailsByCampaignId: (jwtToken, id) =>  
    dispatch(fetchAdGroupDetailsByCampaignId(jwtToken, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsAddAdgroups);

import React from "react";
import { withStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import icons from "../icons";
import {
  AdGroup,
  AdCopy,
  Sitelink,
  Price,
  StructuredSnippet,
  CallOutExtension,
  StarterServicePackList,
  ProfessionalServicePackList,
  PremierServicePackList,
  Custom_Product_Feed_Audit_Fix,
  Custom_Product_Feed_Audit_Fix_10,
  Web_Development,
  SEO_Service,

} from "./view-details-data";
import { useEffect, useState } from "react";

export const HyperLinks = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: "700",
    textDecoration: "underline",
    textTransform: "capitalize",
  },
}))(Button);
const CustomDialog = withStyles((theme) => ({
  paperWidthMd: {
    maxWidth: 470,
  },
}))(Dialog);
const CustomDialogTitle = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(DialogTitle);
const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
  },
}))(ListItem);
const CustomDialogActions = withStyles((theme) => ({
  root: {
    justifyContent: "center",
  },
}))(DialogActions);
const ActionBtn = withStyles((theme) => ({
  root: {
    width: "95%",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  outlinedPrimary: {
    border: "2px solid #1E334E",
    borderRadius: 10,
    "&:hover": {
      border: "2px solid #1E334E",
    },
  },
}))(Button);

function ListGen({ value, icon }) {
  return (
    <CustomListItem>
      <ListItemIcon sx={{ minWidth: 0, marginRight: 2 }}>{icon}</ListItemIcon>
      <ListItemText primary={value} />
    </CustomListItem>
  );
}

function Context({ list, headLine, img }) {
  return (
    <div>
      <CustomDialogTitle>{img}</CustomDialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant={"h5"} component={"span"}>
            {headLine}
          </Typography>
          <List>
            {list.map((value, i) => (
              <ListGen value={value} key={i} icon={icons.dot} />
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
    </div>
  );
}

// class ViewDetails extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       toggle: false,
//     };
//   }

function ViewDetails(props) {
  const { classes } = props;

  const [state, setState] = useState({
    toggle: false,
  });

  const handleClick = (event) => {
    console.log("This Row clicked: ", props.name);
    setState({ toggle: !state.toggle });
  };

  const renderContent = (name) => {
    // console.log("Switch Dialog:", name);
    switch (name) {
      case "Ad Group":
        return <Context list={AdGroup} headLine="Ad Group" />;
      case "Ad Copy":
        return <Context list={AdCopy} headLine="Ad Copy" />;
      case "Sitelink":
        return <Context list={Sitelink} headLine="Sitelink" />;
      case "Price":
        return <Context list={Price} headLine="Price" />;
      case "Structure Snippet":
        return (
          <Context list={StructuredSnippet} headLine="Structure Snippet" />
        );
      case "Callout":
        return <Context list={CallOutExtension} headLine="Callout Extension" />;
      case "Starter Service Pack":
        return (
          <Context
            list={StarterServicePackList}
            headLine="Starter Service Pack"
            img={icons.StarterService}
          />
        );
      case "Professional Service Pack":
        return (
          <Context
            list={ProfessionalServicePackList}
            headLine="Professional Service Pack"
            img={icons.ProfessionalService}
          />
        );
      case "Premier Service Pack":
        return (
          <Context
            list={PremierServicePackList}
            headLine="Premier Service Pack"
            img={icons.PremierService}
          />
        );
      case "Custom Product Feed Audit/Fix":
        return (
          <Context
            list={Custom_Product_Feed_Audit_Fix}
            headLine="Custom Product Feed Audit/Fix"
          // img={icons.PremierService}
          />
        );
        case "Custom Product Feed Audit/Fix 10":
        return (
          <Context
            list={Custom_Product_Feed_Audit_Fix_10}
            headLine="Custom Product Feed Audit/Fix"
          // img={icons.PremierService}
          />
        );
      case "Web Development":
        return (
          <Context
            list={Web_Development}
            headLine="Web Development"
          // img={icons.PremierService}
          />
        );
      case "SEO Service":
        return (
          <Context
            list={SEO_Service}
            headLine="SEO Service"
          // img={icons.PremierService}
          />
        );
      default:
        return "No Data";
    }
  };

  return (
    <div>
      <HyperLinks color="primary" onClick={handleClick}>
        View details
      </HyperLinks>

      <CustomDialog
        open={state.toggle}
        onClose={handleClick}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"md"}
      >
        {renderContent(props.name)}
        <CustomDialogActions>
          <ActionBtn
            onClick={handleClick}
            variant="outlined"
            size="large"
            color="primary"
          >
            Back To List
          </ActionBtn>
        </CustomDialogActions>
      </CustomDialog>
    </div>
  );
}

export default ViewDetails;

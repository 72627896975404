import * as _ from 'lodash';
import rootReducer from './index';
import thunkMiddleware from 'redux-thunk'
import {applyMiddleware, createStore} from 'redux'
import {createMiddleware, EventsMap} from 'redux-beacon';
import logger from '@redux-beacon/logger';
import GoogleAnalytics, {trackPageView, trackEvent} from '@redux-beacon/google-analytics';
import * as types from "../actions/actionTypes"
import {HOLIDAY_PACKAGE_TRACK, REQUEST_DEMO_TRACK} from "../actions/actionTypes";


// export const history = createBrowserHistory()

const eventsMap = {
    [types.SELECT_DATE_RANGE]: trackEvent((action, prevState, nextState) => ({
        category: "DateRange",
        action: "DateRange-Change",
        value: action.dateRanges.itemValue
    })),
    [types.CHANGE_ORGANIZED_BY]: trackEvent((action) => ({
        category: 'OrganizedBy',
        action: `OrganizedBy-${action.organizeBy}`
    })),
    [types.POST_TRIGGER]: trackEvent((action) => ({
        category: 'PostTriggered',
        action: "PostTriggered-Clicked",
    })),
    [types.EXPANSION_STATUS]: trackEvent((action) => ({
        category: `TaskType ${action.data.taskTypeId} Expansion`,
        action: `TaskType ${action.data.taskTypeId} Expansion-${action.data.expansionStatus}`,
    })),
    [types.TASK_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: "Task",
        location: window.location
    })),
    [types.DASHBOARD_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: "Dashboard",
        location: window.location
    })),
    [types.MINI_SERVICE_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Mini Service`,
        location: window.location
    })),
    [types.REQUEST_DEMO_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Request Demo`,
        location: window.location
    })),
    [types.REQUEST_JUMPSTART_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Request Jumpstart`,
        location: window.location
    })),
    [types.FLOATING_CARD_TRACK]: trackEvent((action) => ({
        category: 'FloatingCard',
        label: "Floating Card Button",
        action: "FloatingCard-Clicked",
    })),
    [types.FEEDBACK_TRACK]: trackEvent((action) => ({
        category: 'Feedback',
        label: "Feeback Button",
        action: "Feedback-Clicked",
    })),
    [types.SITE_GRADER_PAGE]: trackPageView((action) => ({
        page: `${window.location}`,
        title: action.reportType == "1" ? `Site Grader Internal Report` : `Site Grader External Report`,
        location: window.location
    })),
    [types.REQUEST_EXPERT_HELP_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Request Expert Help`,
        location: window.location
    })),
    [types.DASHBOARD_VIEW_VALUE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `${pageNames[action.viewValue]}`,
        location: window.location
    })),
    [types.SELECT_GRAPH_DATE_RANGE]: trackEvent((action, prevState, nextState) => ({
        category: "KPI Date Range",
        action: "DateRange-Change",
        value: action.dateRanges.itemValue
    })),
    [types.UPDATE_KPI_TYPE_UI]: trackEvent((action, prevState, nextState) => ({
        category: "KPI Type Change",
        action: `KPI Graph Metric changed to ${KpiString[action.KPIType]}`,
        value: action.KPIType
    })),
    /*[types.TASKS_TABS_TRACK]: trackEvent((action, prevState, nextState) => ({
        category: "Task Page Tabs",
        action: `Task Tab changed to ${taskPageTabs[action.value]}`,
        value: action.KPIType
    })),*/
    [types.TASK_DATA_TRACK]: trackEvent((action) => ({
        category: `TaskType ${action.taskTypeId}`,
        action: `TaskType ${action.taskTypeId}`,
    })),
    [types.HOLIDAY_PACKAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Holiday Package`,
        location: window.location
    })),
    [types.HOME_PAGE]: trackPageView((action) => ({
            page: `${window.location}`,
            title: `Home Page`,
            location: window.location
        })),
    [types.GROWTH_MODEL_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Growth Model Page`,
        location: window.location
    })),
    [types.FEATURES_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Features & Benefits Page`,
        location: window.location
    })),
    [types.FREE_TOOLS_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Free Tools Page`,
        location: window.location
    })),
    [types.GOOGLE_ADS_GRADER_HOME_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Google Ads Grader Home Page`,
        location: window.location
    })),
    [types.SEO_SITE_GRADER_HOME_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `SEO Site Grader Home Page`,
        location: window.location
    })),
    [types.PRICING_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Pricing Page`,
        location: window.location
    })),
     [types.REQUEST_DEMO_FORM_SUBMIT_TRACK]: trackEvent((action) => ({
            category: `Request demo submit`,
            action: `Request demo submit button`,
        })),
     [types.GOOGLE_ADS_FORM_SUBMIT_TRACK]: trackEvent((action) => ({
        category: `Google Ads submit`,
        action: `Google Ads submit button`,
    })),
    [types.SEO_SITE_GRADER_FORM_SUBMIT_TRACK]: trackEvent((action) => ({
        category: `SEO Site Grader submit`,
        action: `SEO Site Grader submit button`,
    })),
    [types.LEAD_GEN_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Lead Generation Page for ${action.eCommercePlatform}`,
        location: window.location
    })),
};
const pageNames = {
    0: "Dashboard",
    1: "Tasks",
    2: "Reports",
    3: "Activity Reports",
}
const taskPageTabs = {
    0: "All",
    1: "Pending",
    2: "Completed",
}
const KpiString = {
    "RC": "R/C",
    "CR": "C/R",
    "CPO": "CPO",
    "CPL": "CPL"
};

export function sumUpImpact(list) {
    return ({
        costSavingForecast: _.sumBy(list, "costSavingForecast"),
        costForecast: _.sumBy(list, "costForecast"),
        revenueForecast: _.sumBy(list, "revenueForecast"),
        orderForecast: _.sumBy(list, "orderForecast"),
    });
}

let ga = GoogleAnalytics();
const gaMiddleware = createMiddleware(eventsMap, ga);
export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, gaMiddleware));

export const idToTaskDataList = (idList, taskDataById = store.getState().taskDataById) => idList.map(id => taskDataById[id]);

export function sumUpImpactOfIds(idList) {
    return sumUpImpact(idToTaskDataList(idList, store.getState().taskDataById));
}

export function sortTasks(tasks, taskDataById = store.getState().taskDataById) {
    const newRatio = _.chain(tasks)
        .mapValues(task => idToTaskDataList(task.dataIdList, taskDataById))
        .mapValues(list => list.filter(td => td.status == 0).length / list.length)
        .value();
    const sorted = _.sortBy(tasks, [
        t => -newRatio[t.summary.groupId],
        // t => -t.summary.totalCount,
        t => t.summary.taskTypePriority,
        t => -t.summary.impact.costSavingForecast || 0,
        t => -t.summary.impact.revenueForecast
    ]);

    /*
        const sortedSummaries = _.map(sorted, t => _.pick(t.summary, ['newRatio', 'totalCount', 'groupId', 'taskTypePriority', 'impact.costSavingForecast', 'impact.revenueForecast']));
        console.log("sortedSummaries = ", sortedSummaries);

    */
    return sorted;
}
import * as types from "./actionTypes";
import { domainUrl } from "./jumpstartDomain";
//For authentication, if success jwt token is received
export function authenticateUser(email, password, rememberMe) {
  return async function(dispatch) {
    const url = domainUrl + "/jumpstart-auth/login";
    let data = "";
    dispatch({
      type: types.JS_AUTHENTICATE_USER_REQUESTED,
      email,
      password,
      rememberMe,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          rememberMe: rememberMe,
        }),
      });

      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.JS_AUTHENTICATE_USER_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_AUTHENTICATE_USER_FAILED,
      });
    }
  };
}

//Fetching all JsAccounts as per the role of the user
export function fetchJsAccounts(token) {
  return async function(dispatch) {
    let data = "";
    const url = domainUrl + "/jumpstart-api/js-accounts";
    dispatch({
      type: types.JS_ACCOUNTS_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }

      // let data = await response.json();
      dispatch({
        type: types.JS_ACCOUNTS_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_ACCOUNTS_FAILED,
      });
    }
  };
}

//Fetching login or username of the user
export function fetchUserInfo(token) {
  return async function(dispatch) {
    const url = domainUrl + "/jumpstart-api/current-user";
    dispatch({
      type: types.JS_USER_INFO_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      let data = await response.json();
      dispatch({
        type: types.JS_USER_INFO_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_USER_INFO_FAILED,
      });
    }
  };
}

//Fetching all user data for admin
export function fetchAllUsersData(token) {
  return async function(dispatch) {
    const url = domainUrl + "/jumpstart-api/users_data";
    dispatch({
      type: types.JS_ALL_USER_DETAILS_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      let data = await response.json();

      dispatch({
        type: types.JS_ALL_USER_DETAILS_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_ALL_USER_DETAILS_FAILED,
      });
    }
  };
}

export function fetchJsAccountById(token, id) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/js-accounts/${id}`;
    dispatch({
      type: types.JS_ACCOUNT_BY_ID_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      let data = await response.json();
      data = { ...data, responseStatus: response.status };
      dispatch({
        type: types.JS_ACCOUNT_BY_ID_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_ACCOUNT_BY_ID_FAILED,
      });
    }
  };
}

export function createUser(token, userData) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/users`;
    dispatch({
      type: types.JS_ADD_USER_REQUESTED,
      userData,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(userData), // Convert userData to JSON string
      });
      let data = "";
      if (response.status == 200 || response.status == 201) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }

      dispatch({
        type: types.JS_ADD_USER_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      console.log("Error in creating user account  ====", e);
      dispatch({
        type: types.JS_ADD_USER_FAILED,
      });
    }
  };
}

export function updateUserDetails(token, userData) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/users`;
    dispatch({
      type: types.JS_UPDATE_USER_REQUESTED,
      userData,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(userData), // Convert userData to JSON string
      });
      let data = "";

      if (response.status == 200 || response.status == 201) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.JS_UPDATE_USER_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      console.log("Error in creating user account  ====", e);
      dispatch({
        type: types.JS_UPDATE_USER_FAILED,
      });
    }
  };
}

export function deleteJSUser(token, email) {
  return async function(dispatch) {
    let data = "";
    const url = domainUrl + `/jumpstart-api/users/${email}`;
    dispatch({
      type: types.JS_DELETE_USER_REQUESTED,
      email,
    });
    try {
      let response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.JS_DELETE_USER_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      console.log("Error in creating user account  ====", e);
      dispatch({
        type: types.JS_DELETE_USER_FAILED,
      });
    }
  };
}

export function passwordReset(emailId) {
  return async function(dispatch) {
    let data = "";
    const url = domainUrl + `/jumpstart-auth/account/reset-password/init`;
    dispatch({
      type: types.PASSWORD_CHANGE_REQUESTED,
      emailId,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
        },
        body: new URLSearchParams({ emailId: emailId }),
      });
      if (response.status == 200) {
        data = { status: response.status };
      } else {
        data = { status: response.status };
        data = { status: response.status };
      }

      dispatch({
        type: types.PASSWORD_CHANGE_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      console.log("Error in password reset  ====", e);
      dispatch({
        type: types.PASSWORD_CHANGE_FAILED,
      });
    }
  };
}

export function finishPasswordReset(newPassword, key) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-auth/account/reset-password/finish`;
    let data = "";
    dispatch({
      type: types.FINISH_PASSWORD_RESET_REQUESTED,
      newPassword,
      key,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: new URLSearchParams({
          newPassword: newPassword,
          key: key,
        }),
      });
      if (response.status == 200) {
        data = { status: response.status };
      } else {
        data = await response.json();
        data = { status: response.status };
      }

      dispatch({
        type: types.FINISH_PASSWORD_RESET_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FINISH_PASSWORD_RESET_FAILED,
      });
    }
  };
}
//business-details update
export function updateBusinessDetails(token, businessDetails) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/business-details`;
    let data = "";
    dispatch({
      type: types.UPDATE_BUSINESS_DETAILS_REQUESTED,
      businessDetails,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(businessDetails), // Convert userData to JSON string
      });
      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.UPDATE_BUSINESS_DETAILS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.UPDATE_BUSINESS_DETAILS_FAILED,
      });
    }
  };
}
//fetch business details based on jsaccount id
export function fetchBusinessDetailsByJsAccountId(token, id) {
  return async function(dispatch) {
    let data = "";
    const url = domainUrl + `/jumpstart-api/business-details/js-account/${id}`;

    dispatch({
      type: types.JS_BUSINESS_DETAILS_BY_ID_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.JS_BUSINESS_DETAILS_BY_ID_RECEIVED,
        data,
      });
      dispatch({
        type: types.JS_BUSINESS_DETAILS_BY_ID_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      console.log("Error in fetching js account info ====", e);
      dispatch({
        type: types.JS_BUSINESS_DETAILS_BY_ID_FAILED,
      });
    }
  };
}

// After clicking on the done in the toggle we are storing the data in the DB storeUpdatedCampaignData
// storeUpdatedCampaignData
export function storeUpdatedCampaignData(token, campaigns) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/campaign-structure`;
    let data = "";

    dispatch({
      type: types.UPDATE_STORE_CAMPAIGN_DETAILS_REQUESTED,
      campaigns,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(campaigns), // Convert userData to JSON string
      });
      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.UPDATE_STORE_CAMPAIGN_DETAILS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.UPDATE_STORE_CAMPAIGN_DETAILS_FAILED,
      });
    }
  };
}

export function createAccountStructure(token, id) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/account-setup-sync-status/${id}`;
    let data = "";
    dispatch({
      type: types.CREATE_ACCOUNT_STRUCTURE_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.CREATE_ACCOUNT_STRUCTURE_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.CREATE_ACCOUNT_STRUCTURE_FAILED,
      });
    }
  };
}

export function fetchSyncCheckId(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/create-account-structure/js-account/${id}`;
    let data = "";
    dispatch({
      type: types.FETCH_JS_SYNC_CHECK_ID_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.text();
      data = { syncCheckId: data, status: response.status };

      dispatch({
        type: types.FETCH_JS_SYNC_CHECK_ID_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_JS_SYNC_CHECK_ID_FAILED,
      });
    }
  };
}

export function fetchCallouts(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/callout-extensions/js-account/${id}`;
    let data = "";
    dispatch({
      type: types.FETCH_CALLOUTS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.FETCH_CALLOUTS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_CALLOUTS_FAILED,
      });
    }
  };
}

export function generateNewCallouts(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/generate-callout-extensions/js-account/${id}`;
    let data = "";
    dispatch({
      type: types.NEW_GENERATED_CALLOUTS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };

      dispatch({
        type: types.NEW_GENERATED_CALLOUTS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.NEW_GENERATED_CALLOUTS_FAILED,
      });
    }
  };
}

export function saveCallouts(token, callouts) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/callout-extensions`;
    let data = "";
    dispatch({
      type: types.SAVE_CALLOUTS_REQUESTED,
      callouts,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(callouts),
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.SAVE_CALLOUTS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.SAVE_CALLOUTS_FAILED,
      });
    }
  };
}

export function fetchSitelinks(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/sitelink-extensions/js-campaign/${id}`;
    let data = "";
    dispatch({
      type: types.FETCH_SITELINKS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.FETCH_SITELINKS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_SITELINKS_FAILED,
      });
    }
  };
}

export function saveSitelinks(token, sitelinks) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/sitelink-extensions`;
    let data = "";
    dispatch({
      type: types.SAVE_SITELINKS_REQUESTED,
      sitelinks,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sitelinks),
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.SAVE_SITELINKS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.SAVE_SITELINKS_FAILED,
      });
    }
  };
}
export function generateSitelinks(token, id, productFocus, pageUrl) {
  return async function(dispatch) {
    const url =
      domainUrl +
      `/jumpstart-api/generate-sitelink-extensions/js-campaign/${id}?productFocus=${productFocus}&pageUrl=${pageUrl}`;
    let data = "";
    dispatch({
      type: types.NEW_GENERATED_SITELINKS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      data = await response.json();
      data = { ...data, status: response.status };

      dispatch({
        type: types.NEW_GENERATED_SITELINKS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.NEW_GENERATED_SITELINKS_FAILED,
      });
    }
  };
}

export function fetchCampaignDetails(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/campaign-structure/js-account/${id}`;
    let data = "";
    dispatch({
      type: types.FETCH_CAMPAIGN_DETAILS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.FETCH_CAMPAIGN_DETAILS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_CAMPAIGN_DETAILS_FAILED,
      });
    }
  };
}

// RSA Fetch, Update/Save, Generate Headlines, Descriptions, Paths

export function fetchRsaDetails(token, id) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/responsive-ads/js-adgroup/${id}`;
    let data = "";
    dispatch({
      type: types.FETCH_RESPONSIVE_ADS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.FETCH_RESPONSIVE_ADS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_RESPONSIVE_ADS_FAILED,
      });
    }
  };
}

export function saveRsaDetails(token, rsa) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/responsive-ads`;
    let data = "";
    dispatch({
      type: types.SAVE_RSA_DATA_REQUESTED,
      rsa,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify([rsa]),
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.SAVE_RSA_DATA_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.SAVE_RSA_DATA_FAILED,
      });
    }
  };
}

export function generateNewHeadlines(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl +
      `/jumpstart-api/generate-rsa-headlines/js-adgroup/${id}`;
    let data = "";
    dispatch({
      type: types.GENERATE_RSA_HEADLINES_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.GENERATE_RSA_HEADLINES_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.GENERATE_RSA_HEADLINES_FAILED,
      });
    }
  };
}
export function generateNewDescriptions(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/generate-rsa-descriptions/js-adgroup/${id}`;
    let data = "";
    dispatch({
      type: types.GENERATE_RSA_DESCRIPTIONS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.GENERATE_RSA_DESCRIPTIONS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.GENERATE_RSA_DESCRIPTIONS_FAILED,
      });
    }
  };
}
export function generateNewPaths(token, id) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/generate-rsa-paths/js-adgroup/${id}`;
    let data = "";
    dispatch({
      type: types.GENERATE_RSA_PATHS_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.GENERATE_RSA_PATHS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.GENERATE_RSA_PATHS_FAILED,
      });
    }
  };
}

export function fetchKeywords(token, id, pageNo, orderBy, order,show,keywordFilters,searchTerm) {
  return async function(dispatch) {
    const pageSize = 10;
    const url =
      domainUrl +
      `/jumpstart-api/keyword-structure/js-adgroup/${id}?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${orderBy}&sortDir=${order}&showStatus=${show}`;
    let data = "";
    let finalKeywordFilterRequest = {
      keywordFilters: keywordFilters,
      searchDetails : searchTerm,
    }
    dispatch({
      type: types.FETCH_KEYWORDS_REQUESTED,
      id,
      pageNo,
      orderBy,
      order,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(finalKeywordFilterRequest),
      });
      data = await response.json();
      data = { ...data, status: response.status };

      dispatch({
        type: types.FETCH_KEYWORDS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      console.log("exception here", e);
      dispatch({
        type: types.FETCH_KEYWORDS_FAILED,
      });
    }
  };
}

export function updateKeywords(token, keywords) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/keyword-structure`;
    let data = "";
    dispatch({
      type: types.UPDATE_KEYWORDS_REQUESTED,
      keywords,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(keywords),
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.UPDATE_KEYWORDS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.UPDATE_KEYWORDS_FAILED,
      });
    }
  };
}

//fetch business details based on jsaccount id
export function fetchCampaignDetailsByJsAccountId(token, id) {
  return async function(dispatch) {
    let data = "";

    const url =
      domainUrl + `/jumpstart-api/campaign-structure/js-account/${id}`;
    dispatch({
      type: types.JS_CAMPAIGN_DETAILS_BY_ID_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      // let data = await response.json();
      if (response.status === 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { status: response.status };
      }

      dispatch({
        type: types.JS_CAMPAIGN_DETAILS_BY_ID_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      console.log("Error in fetching campaign details info ====", e);
      dispatch({
        type: types.JS_CAMPAIGN_DETAILS_BY_ID_FAILED,
      });
    }
  };
}
//storing adgroups
export function storeAdgroupData(token, adgroups) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/adgroup-structure`;
    let data = "";

    dispatch({
      type: types.STORE_ADGROUP_DETAILS_REQUESTED,
      adgroups,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(adgroups), // Convert userData to JSON string
      });

      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.STORE_ADGROUP_DETAILS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.STORE_ADGROUP_DETAILS_FAILED,
      });
    }
  };
}
export function fetchAdGroupDetailsByCampaignId(token, id) {
  return async function(dispatch) {
    let data = "";

    const url = domainUrl + `/jumpstart-api/adgroup-structure/campaign-id/${id}`;
    dispatch({
      type: types.JS_ADGROUP_DETAILS_BY_ID_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      if (response.status === 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data,status: response.status };
      }
      dispatch({
        type: types.JS_ADGROUP_DETAILS_BY_ID_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_ADGROUP_DETAILS_BY_ID_FAILED,
      });
    }
  };
}
export function fetchAdgroupStructure(token, id) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/adgroup-structure/js-account/${id}`;
    let data = "";
    dispatch({
      type: types.FETCH_ADGROUP_STRUCTURE_REQUESTED,
      id,
    });

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.FETCH_ADGROUP_STRUCTURE_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_ADGROUP_STRUCTURE_FAILED,
      });
    }
  };
}

export function deleteAdgroupData(token, adgroups) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/adgroup-structure`;
    let data = "";

    dispatch({
      type: types.DELETE_ADGROUP_DETAILS_REQUESTED,
      adgroups,
    });
    try {
      let response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify(adgroups), // Convert userData to JSON string
      });

      if (response.status == 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data, status: response.status };
      }
      dispatch({
        type: types.DELETE_ADGROUP_DETAILS_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.DELETE_ADGROUP_DETAILS_FAILED,
      });
    }
  };
}

export function submitCampaignFramework(token, id) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/submit-campaign-framework/${id}`;
    let data = "";

    dispatch({
      type: types.SUBMIT_CAMPAIGN_FRAMEWORK_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Set content type to JSON
        },
      });
      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.SUBMIT_CAMPAIGN_FRAMEWORK_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.SUBMIT_CAMPAIGN_FRAMEWORK_FAILED,
      });
    }
  };
}

export function submitCampaignStructure(token, id) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/submit-campaign-structure/${id}`;
    let data = "";

    dispatch({
      type: types.SUBMIT_CAMPAIGN_STRUCTURE_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      data = await response.json();
      data = { ...data, status: response.status };
      dispatch({
        type: types.SUBMIT_CAMPAIGN_STRUCTURE_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.SUBMIT_CAMPAIGN_STRUCTURE_FAILED,
      });
    }
  };
}

export function campaignFrameworkDownload(token, id, companyName) {
  return async function(dispatch) {
    const url =
      domainUrl + `/jumpstart-api/download-account-structure/js-account/${id}`;
    let data = "";
   
    dispatch({
      type: types.DOWNLOAD_CAMPAIGN_FRAMEWORK_REQUESTED,
      id,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      // Format the date string
      const formattedDate = `${month}-${day}-${year}`;
      
      const filename = companyName+formattedDate+".xlsx";

      const blob = await response.blob();
      const status = response.status;
      // const data = response.body;
      const urlNew = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlNew;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch({
        type: types.DOWNLOAD_CAMPAIGN_FRAMEWORK_RECEIVED,
        status,
      });

      return status;
    } catch (e) {
      dispatch({
        type: types.DOWNLOAD_CAMPAIGN_FRAMEWORK_FAILED,
      });
    }
  };
}


export function fetchAdGroupDetailsByJsAccountIdTEST(token, id) {
  return async function(dispatch) {
    let data = "";
    const url = domainUrl + `/jumpstart-api/adgroup-structure/js-account/${id}`;
    dispatch({
      type: types.JS_ADGROUP_DETAILS_BY_ID_TEST_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      if (response.status === 200) {
        data = await response.json();
        data = { ...data, status: response.status };
      } else {
        data = { ...data,status: response.status };
      }
      dispatch({
        type: types.JS_ADGROUP_DETAILS_BY_ID_TEST_RECEIVED,
        data,
      });
    
      return data;
    } catch (e) {
      dispatch({
        type: types.JS_ADGROUP_DETAILS_BY_ID_TEST_FAILED,
      });
    }
  };
}

export function deleteCampaignStructure(token,campaign) {
  return async function(dispatch) {
    const url = domainUrl + `/jumpstart-api/campaign-structure`;
    let data = "";

    dispatch({
      type: types.DELETE_CAMPAIGN_REQUESTED,
      campaign,
    });
    try {
      let response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(campaign),
      });

        data = await response.json();
        data = { ...data, status: response.status };
      dispatch({
        type: types.DELETE_CAMPAIGN_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.DELETE_CAMPAIGN_FAILED,
      });
    }
  };
}

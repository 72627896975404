import React, { useEffect, useState } from "react";
import "./jumpstart.css";
import { Box, Grid, InputAdornment, Paper, Popper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";

function JsSearchBar(props) {
  const {
    component,
    data,
    handleSelectedAcc,
    handleFilterSearch,
    handleClearSearch,
  } = props;

  const handleOnSelect = (event, item, reason) => {
    if (reason === "clear") {
      handleClearSearch();
    } else {
      let selected;
      if (component == "JsAccounts") {
        selected = data.find((row) => row.companyName == item);
        handleSelectedAcc(selected);
      } else {
        selected = data.find((row) => row.email == item);
        handleSelectedAcc(selected);
      }
    }
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Autocomplete
          size="medium"
          noOptionsText="No data found"
          sx={{
            border: "none",
            borderRadius: 0,
            backgroundColor: "#F8F9F9",
            width: "100%",
          }}
          slotProps={{
            paper: {
              sx: {
                "& .MuiAutocomplete-listbox": {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1rem",
                    wordBreak: "break-all",
                  },
                },
              },
            },
            popper:{
              sx: {
                zIndex: 20,
              }
            }
          }}
          onChange={handleOnSelect}
          id="controllable-states-demo"
          options={
            component == "JsAccounts"
              ? data && data.map((option) => option.companyName)
              : data && data && data.map((option) => option.email)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                component === "JsAccounts"
                  ? "Type company name to search..."
                  : "Type email to search..."
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="large"
                      sx={{
                        borderRight: "1px solid #9da6b2",
                        paddingRight: "8px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
export default JsSearchBar;

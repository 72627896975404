import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Hidden,
  Input,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { saveGraderDetails } from "../../actions";
import {
  ErrorTypography,
  ErrorSmallTypography,
} from "../../components/typography";
import Snackbar from "../../components/snackbars";
import { ExternalOrangeBtnNew, OrangeBtnNew } from "../../components/buttons";
import icons from "../../components/icons";
import BrandDialog from "../../components/dashboard/brand-dialog";
import SafeAndSecure from "../../components/safe-and-secure";
import * as types from "../../actions/actionTypes";
import { DemoInputField } from "../../components/input-forms";
import NumberFormat from "react-number-format";

const PlainInput = withStyles((theme) => ({
  underline: {
    "&::before": {
      borderBottom: "1px solid " + theme.palette.text.darkGrey,
    },
    "&:hover": {
      borderBottom: "unset",
    },
    "&:after": {
      borderBottom: "2px solid " + theme.palette.orangeColor,
    },
  },
}))(Input);

const CustomTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.dialogText,
  },
}))(Typography);

const styles = (theme) => ({
  cards: {
    maxWidth: 250,
    textAlign: "center",
    boxShadow: "unset",
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  hyperText: {
    color: theme.palette.orangeColor,
  },
  buttonH: {
    height: "10vh",
    // [theme.breakpoints.down('md')]: {
    //     height: "12vh",
    // },
    [theme.breakpoints.down("xl")]: {
      height: "9.5vh",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      height: "6.5vh",
    },
  },
  btn: {
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      padding: "18px 45px",
    },
  },
  fromH: {
    minHeight: "14vh",
    marginBottom: 15,
    [theme.breakpoints.down("xl")]: {
      minHeight: "6vh",
    },
  },
  formHeight: {
    height: "3em",
    [theme.breakpoints.down("xl")]: {
      width: "100%",
    },
  },
});

function GraderHomePageForm(props) {
  const { classes } = props;

  console.log("The props", props);

  window.refreshAfterSuccessFromAdwordsGrader = () => {
    window.location = "/import-status-grader.html";
  };
  console.log("window.lxrOptions are", window.lxrOptions);

  const [authorizationUrl, setAuthorizationUrl] = useState(
    window.lxrOptions?.authorizationUrl || null
  );
  const [emailId, setEmailId] = useState(
    props.defaultEmailId !== "" ? props.defaultEmailId : ""
  );
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [url, setUrl] = useState("");
  const [dateRange, setDateRange] = useState("1");
  const [customerId, setCustomerId] = useState("");
  const [monthlySpend, setMonthlySpend] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [error, setError] = useState(false);
  const [safeAndSecureDialog, setSafeAndSecureDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState(window.lxrOptions?.errorMsg || null);
  const [termsAndConditions, setTermAndConditions] = useState(false);

  useEffect(() => {
    if (window.lxrOptions?.authorizationUrl === undefined) {
      window.lxrOptions = {
        ...window.lxrOptions,
        // authorizationUrl: null,
        authorizationUrl:
          "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=865479052095.apps.googleusercontent.com&redirect_uri=http://localhost:8080/accountConfigurationForGrader.html&scope=https://www.googleapis.com/auth/adwords&state=g3e9crjr24ergr7go1ebth31tn&access_type=offline&approval_prompt=force&include_granted_scopes=true",
      };
    }

    if (window.lxrOptions && window.lxrOptions.errorMsg) {
      window.lxrOptions.errorMsg = "";
    }

    setAuthorizationUrl(window.lxrOptions?.authorizationUrl || null);
    setEmailId(props.defaultEmailId !== "" ? props.defaultEmailId : "");
    setUserName("");
    setPhoneNumber("");
    setUrl("");
    setDateRange("1");
    setCustomerId("");
    setMonthlySpend("");
    setEmailIdError("");
    setPhoneNumberError("");
    setError();
    setSafeAndSecureDialog(false);
    setErrorMsg("");
    setTermAndConditions(false);
  }, []);

  function InformationCards({ icon, text, classes }) {
    return (
      <Grid item>
        <Card className={classes.cards}>
          <CardContent className={classes.cardContent}>
            {icon}
            <CustomTypography style={{ fontSize: 14 }}>{text}</CustomTypography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  const saveUserDetails = async () => {
    const response = await props.saveGoogleAdDetails(
      emailId,
      userName,
      phoneNumber,
      url,
      dateRange,
      customerId,
      monthlySpend
    );
    console.log("The response is", response);
    if (response && response.error && response.error != null) {
      setError(true);
    } else {
      window.open(
        authorizationUrl,
        "authorizationUrl",
        "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no"
      );
    }
  };

  const validate = () => {
    let isError = false;
    const errors = {};
    errors.errorMsg = null;
    console.log("we are inside the validate function", emailId, phoneNumber);
    if (window.lxrOptions && window.lxrOptions.errorMsg) {
      window.lxrOptions.errorMsg = "";
    }
    if ((emailId && emailId.length === 0) || emailId.trim() == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if ((emailId && emailId.length > 0) || emailId.trim() > 0) {
      let result = emailId
        .trim()
        .match(
          /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if ((phoneNumber && phoneNumber.length == 0) || phoneNumber.trim() == 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (
      phoneNumber &&
      phoneNumber.length > 0 &&
      phoneNumber.length < 10
    ) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 10 digits";
    } else if (
      phoneNumber &&
      phoneNumber.length != 0 &&
      phoneNumber.length > 10
    ) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }
  
    setEmailIdError(errors.emailIdError);
    setPhoneNumberError(errors.phoneNumberError);

    return isError;
  };

  const handleSubmit = (event) => {
    console.log("we are inside the handle submit", emailId, phoneNumber);
    event.preventDefault();
    let error = validate();
    props.googleAdsFormSubmit();
    if (!error) {
      setSafeAndSecureDialog(true);
    }
  };

  const handleClose = () => {
    setError(false);
  };

  const handleDialogClose = async()=> {
    
    await setSafeAndSecureDialog(false);
  };

  const handleAction = async () => {
    console.log("handleAction")
    handleDialogClose();
    await saveUserDetails();
  };

  const dialogTitle = (
    <div>
      <CustomTypography>
        Redirecting to Google authorization page{" "}
      </CustomTypography>
      <CustomTypography>
        Please follow these steps to get your free Google Ads scorecard
      </CustomTypography>
    </div>
  );

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={classes.fromH}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.dialogP}
          >
            <Grid item md={12} sm={12} className={classes.formHeight}>
              <PlainInput
                placeholder="Your Email..."
                className={classes.input}
                onChange={(event) => setEmailId(event.target.value)}
                // onChange={handleChange("emailId")}
                inputProps={{
                  "aria-label": "Description",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                error={emailIdError !== ""}
                defaultValue={emailId}
                helperText={emailIdError}
                style={{ color: "#333333", height: "2em" }}
              />
              <Hidden lgDown>
                {" "}
                {emailIdError !== "" ? (
                  <ErrorSmallTypography
                  align={"left"}
                  >
                    <b>{emailIdError}</b>
                  </ErrorSmallTypography>
                ) : (
                  ""
                )}
                {errorMsg != null && errorMsg != "" ? (
                  <ErrorSmallTypography
                    align={"center"}
                    varient={"h6"}
                    component={"h6"}
                    className={classes.errorText}
                  >
                    <b>Error: {errorMsg}</b>
                  </ErrorSmallTypography>
                ) : null}
              </Hidden>
            </Grid>
            <Grid item md={12} sm={12} className={classes.formHeight}>
              <NumberFormat
                placeholder="Your Phone Number..."
                fullWidth
                margin="normal"
                name="phoneNumber"
                value={phoneNumber}
                // onValueChange={handlePhoneNumberChange}
                onValueChange={async (values) => {
                  const { formattedValue, value } = values;
                  await setPhoneNumber(value);
                }}
                error={phoneNumberError !== ""}
                helperText={phoneNumberError}
                InputLabelProps={{
                  shrink: true,
                }}
                customInput={PlainInput}
                format="###-###-####"
                mask="_"
                style={{ color: "#333333", height: "2em" }}
              />
              {phoneNumberError != "" ? (
                <ErrorSmallTypography
                  className={classes.errorTypo}
                  align={"left"}
                >
                  <b>{phoneNumberError}</b>
                </ErrorSmallTypography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
        <Hidden smUp>
          <div>
            {emailIdError !== "" ? (
              <ErrorTypography>
                <b>{emailIdError}</b>
              </ErrorTypography>
            ) : (
              ""
            )}
            {errorMsg != null && errorMsg != "" ? (
              <ErrorTypography
                align={"center"}
                varient={"h6"}
                component={"h6"}
                className={classes.errorText}
              >
                <b>Error: {errorMsg}</b>
              </ErrorTypography>
            ) : null}
          </div>
        </Hidden>
        <div className={classes.buttonH}>
          <ExternalOrangeBtnNew
            className={classes.btn}
            type="submit"
            size="medium"
            variant="contained"
            color="primary"
            fullWidth={true}
          >
            Get Your Free Google Ads Scorecard
          </ExternalOrangeBtnNew>
        </div>
        <Typography
          style={{ boxShadow: "black", fontWeight: "400" }}
          component={"p"}
          variant={"caption"}
          color={"textPrimary"}
          align={"left"}
        >
          Do not have a Google Ads account?{" "}
          <a
            className={classes.hyperText}
            target={"_blank"}
            // href={"/adwords-grader.html#/request-jumpstart"}>Click
            href={"/expert-help.html?channel=ppc"}
          >
            Click Here
          </a>
        </Typography>
      </form>
      <Snackbar
        verticalAlign={"bottom"}
        horizontalAlign={"left"}
        // open={snackbarOpen}
        duration={3000}
        handleClose={handleClose}
        onClose={handleClose}
        variant={"error"}
        message={"Something Went Wrong, Please Try again."}
      />
      <BrandDialog
        title={dialogTitle}
        // open={true}
        open={safeAndSecureDialog}
        handleClose={handleDialogClose}
        fullWidth
        safeAndSecure
        handleAction={handleAction}
        // counter={counter}
      >
        <Grid container justifyContent={"space-around"}>
          <InformationCards
            icon={icons.block1}
            text={
              <span>
                Choose the email associated with your <b>Google Ads account</b>
              </span>
            }
            classes={classes}
          />
          <InformationCards
            icon={icons.block2}
            text={
              "Hit “Allow” (LXRGuide will only use your login info to grade your account)"
            }
            classes={classes}
          />
          <InformationCards
            icon={icons.block3}
            text={"See your results!"}
            classes={classes}
          />
          <Grid item className={"fullWidth"}>
            <SafeAndSecure dialog />
          </Grid>
        </Grid>
      </BrandDialog>
    </div>
  );
}
GraderHomePageForm.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  defaultEmailId: state.leadGeneration.emailId,
});

const mapDispatchToProps = (dispatch) => ({
  saveGoogleAdDetails: (
    emailId,
    userName,
    phoneNumber,
    url,
    dateRange,
    customerId,
    monthlySpend
  ) => {
    return dispatch(
      saveGraderDetails(
        emailId,
        userName,
        phoneNumber,
        url,
        dateRange,
        customerId,
        monthlySpend
      )
    );
  },
  googleAdsFormSubmit: () =>
    dispatch({ type: types.GOOGLE_ADS_FORM_SUBMIT_TRACK }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GraderHomePageForm));

import React, { Component } from "react";
// import SEOSiteGraderInternalStyles from "../../assets/jss/siteGarderInternal/SEOSiteGraderInternalStyles";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import NewScoreCard from "../../components/siteGrader/newScoreCard";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  getsSiteGraderInternalResult,
  getsSiteGraderResult,
  seoReportStatusUpdation,
  siteGraderReset,
} from "../../actions";
import { SITE_GRADER_PAGE } from "../../actions/actionTypes";
import { InternalReportTitle } from "../SEOSiteGraderInternal/templates";
import OnPageSeo from "../SEOSiteGraderInternal/onPageSeo";
import WebsiteTraffic from "../SEOSiteGraderInternal/websiteTraffic";
import PaidSearchTraffic from "../SEOSiteGraderExternal/paidSerachTraffic";
import Mobile from "../SEOSiteGraderInternal/Mobile";
import SpeedMeters from "../SEOSiteGraderInternal/speedingMeters";
import TopKeywords from "../SEOSiteGraderInternal/topKeywords";
import green from "@mui/material/colors/green";
import DetailedReport from "../../components/internalSiteGrader/DetailedReport";
import { ErrorTypography } from "../../components/typography";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "../../components/snackbars";
import {
  NewOutLinedRectBlackTextBtn,
  OutLinedBlackBtn,
} from "../../components/buttons";
import ErrorPage from "../../components/internalSiteGrader/ErrorPage";
import { createHashHistory } from "history";
import icons from "../../components/icons";
import DetailedReportForm from "../SEOSiteGraderExternal/detailedReportForm";
import ReactDOM from "react-dom";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let moment = require("moment");

class SEOSiteGraderInternal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      domainUrl: null,
      id: window.location.search
        ? new URLSearchParams(window.location.search).get("domainId")
        : 0,
      url: window.location.search
        ? new URLSearchParams(window.location.search).get("domainUrl")
        : "",
      type:
        window.location.pathname &&
        window.location.pathname == "/dashboard.html"
          ? props.match.params.type
          : new URLSearchParams(window.location.search).get("type"),
      seoReportStatus:
        this.props.siteGrader.seoReportStatus == true ? "1" : "0",
      alert: false,
      seoReportStatusLoading: false,
    };
  }

  handleClose = () => {
    this.setState({ alert: false });
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
    window.scrollTo(0, 0);
    document.title = "LXRGuide - SEO Score Card";
    const { siteGrader } = this.props;
    this.props.siteGraderPageTrack(this.state.type);
    if (siteGrader && siteGrader.siteGraderScore == 0 && this.state.type != 1) {
      this.props.getSiteGraderResult(this.state.id, this.state.url);
    } else if (this.state.type == 1) {
      this.props.getsSiteGraderInternalResult();
    }
  }

  handleChange = async (event) => {
    const { siteGrader } = this.props;
    // await this.setState({seoReportStatus: !this.state.seoReportStatus});
    this.setState({ seoReportStatusLoading: true });
    const response = await this.props.seoReportStatusUpdation(
      !this.props.siteGrader.seoReportStatus,
      siteGrader.clientId
    );
    this.setState({ seoReportStatusLoading: false });
    await this.setState({ alert: true });
  };
  handleRefresh = async () => {
    console.log("handleRefresh");
    const { siteGrader } = this.props;
    await this.setState({ domainUrl: siteGrader.url });
    this.setState({ isLoading: true });
    await this.props.siteGraderReset();
    let path = `/site-grader-refresh/${this.state.domainUrl}`;
    let history = createHashHistory();
    history.push(path);
  };

  render() {
    const { classes, siteGrader, width } = this.props;
    console.log("window.location==", window.location);
    const { alert, type } = this.state;
    // const {type} = this.props.match.params;
    const {
      isLoading,
      redirectedURL,
      lxrSeoDomainURL,
      serverError,
      clientUpdateCheckError,
    } = this.props.siteGrader;
    const mobilePageLoadTime =
      this.props.siteGrader.speed != null
        ? this.props.siteGrader.speed.mobilePageLoadTime
        : 0;
    const desktopPageLoadTime =
      this.props.siteGrader.speed != null
        ? this.props.siteGrader.speed.desktopPageLoadTime
        : 0;
    const siteGraderScore =
      this.props.siteGrader != null ? this.props.siteGrader.siteGraderScore : 0;
    const domainId =
      this.props.siteGrader != null ? this.props.siteGrader.domainId : 0;
    console.log("siteGrader=", siteGrader);
    const fetchDate =
      this.props.siteGrader != null && this.props.siteGrader.fetchDate
        ? this.props.siteGrader.fetchDate
        : 0;
    let today = moment().format("YYYY-MM-DD");
    return (
      <div>
        {serverError != null && serverError != "" ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            style={{ height: "40em" }}
          >
            {serverError == "Invalid Client Website URL or No Report Found" ? (
              <Grid item>
                <ErrorPage
                  siteGrader={siteGrader}
                  handleRefresh={this.handleRefresh}
                />
              </Grid>
            ) : (
              <Grid item>
                <ErrorTypography>{serverError}</ErrorTypography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Card className={classes.mainCard}>
            <CardContent>
              {isLoading ? (
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{ height: "40em" }}
                >
                  <Grid item>
                    <CircularProgress color={"secondary"} />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2} alignItems={"center"}>
                  {/*<Grid item md={type && type == "0" ? 6 : 12} sm={12} xs={12}*/}
                  <Grid
                    item
                    md={6}
                    sm={12}
                    xs={12}
                    // className={type && type == "0" ? "flex" : ""}>
                    className={[
                      "flex",
                      type && type == "0" ? classes.mainTitleAlignment : null,
                    ]}
                  >
                    <Typography
                      variant={"h4"}
                      color={"textPrimary"}
                      className={classes.newMainTitle}
                    >
                      <b>Your Website Performance</b>
                    </Typography>
                    <br />
                  </Grid>
                  {type && type == "1" ? (
                    <Grid item md={6} sm={12} xs={12}>
                      <Grid
                        container
                        justifyContent={
                          width == "sm" || width == "xs"
                            ? "flex-start"
                            : "flex-end"
                        }
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Typography
                            variant={"subtitle1"}
                            color={"textPrimary"}
                            className={classes.timeText}
                          >
                            <b>
                              Website analyzed on :{" "}
                              {moment(fetchDate).format("LLLL")}
                            </b>
                          </Typography>
                        </Grid>

                        <Grid item>
                          <NewOutLinedRectBlackTextBtn
                            disabled={
                              (siteGrader.userId != null &&
                                siteGrader.userId == 2) ||
                              moment(today).diff(
                                moment(fetchDate).format("YYYY-MM-DD")
                              ) == 0
                            }
                            onClick={this.handleRefresh}
                          >
                            Refresh
                          </NewOutLinedRectBlackTextBtn>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Paper
                          className={classNames(
                            classes.padding2X,
                            classes.cardContent,
                            classes.paper,
                            type && type == "0"
                              ? classes.previewExternal
                              : classes.preview
                          )}
                        >
                          <Typography
                            className={(classes.text, classes.pb)}
                            color={"textPrimary"}
                            variant={"body2"}
                          >
                            <b>Preview</b>
                          </Typography>
                          <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            className={classes.imgContainer}
                          >
                            <Grid item md={9} className={classes.gridp}>
                              <img
                                className={classes.img}
                                src={`${lxrSeoDomainURL}/screenshot/${domainId}_desk.png`}
                                height={241}
                                alt={
                                  "Sorry we are experiencing difficulty in contacting the server"
                                }
                              />
                            </Grid>
                            <Grid item md={8} className={classes.dStand}>
                              {icons.displayStand}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid xs={12} sm={12} item md={6}>
                        <Card
                          className={classNames(
                            classes.cardClass,
                            classes.bigCard
                          )}
                        >
                          <CardContent
                            className={classes.cardContent}
                            style={{ height: "100%" }}
                          >
                            <Typography
                              align={"left"}
                              color={"textPrimary"}
                              className={classes.text}
                            >
                              <b>Site Score</b>
                            </Typography>
                            <InternalReportTitle
                              siteGraderScore={siteGraderScore}
                              redirectedURL={redirectedURL}
                              classes={classes}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        {type && type == "0" ? (
                          <PaidSearchTraffic
                            classes={classes}
                            siteGrader={siteGrader}
                            type={type}
                          />
                        ) : (
                          <TopKeywords
                            classes={classes}
                            siteGrader={siteGrader}
                            type={type}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Card
                          className={classNames(
                            classes.cardClass,
                            classes.smallCard
                          )}
                        >
                          <CardContent
                            className={classes.cardContent}
                            style={{ height: "100%" }}
                          >
                            <NewScoreCard data={siteGrader} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Card
                          className={classNames(
                            classes.cardClass,
                            classes.smallCard
                          )}
                        >
                          <CardContent
                            className={classes.cardContent}
                            style={{ height: "100%" }}
                          >
                            <OnPageSeo
                              classes={classes}
                              siteGrader={siteGrader}
                              type={type}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <Card
                          className={classNames(
                            classes.cardClass,
                            classes.smallCard
                          )}
                        >
                          <CardContent
                            className={classes.cardContent}
                            style={{ height: "100%" }}
                          >
                            <WebsiteTraffic
                              classes={classes}
                              siteGrader={siteGrader}
                              type={type}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6}>
                            <Card
                              className={classNames(
                                classes.cardClass,
                                classes.mobileCard
                              )}
                            >
                              <CardContent className={classes.cardContent}>
                                <Mobile siteGrader={siteGrader} />
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item sm={12} xs={12} md={6}>
                            <Grid container spacing={2}>
                              <Grid item sm={12} xs={12} md={12}>
                                <Card
                                  className={classNames(
                                    classes.cardClass,
                                    classes.middleCard
                                  )}
                                >
                                  <CardContent className={classes.cardContent}>
                                    <SpeedMeters
                                      classes={classes}
                                      mobilePageLoadTime={mobilePageLoadTime}
                                      desktopPageLoadTime={desktopPageLoadTime}
                                      type={type}
                                    />
                                  </CardContent>
                                </Card>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                {type && type != "1" ? (
                                  <DetailedReportForm
                                    classes={classes}
                                    domainId={this.state.id}
                                  />
                                ) : (
                                  <DetailedReport
                                    siteGrader={siteGrader}
                                    handleClose={this.handleClose}
                                    clientUpdateCheckError={
                                      clientUpdateCheckError
                                    }
                                    alert={alert}
                                    seoReportStatusLoading={
                                      this.state.seoReportStatusLoading
                                    }
                                    handleChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

SEOSiteGraderInternal.propTypes = {};
const SEOSiteGraderInternalStyles = (theme) => ({
  paper: {
    boxShadow: "unset",
  },
  mainCard: {
    boxShadow: "unset",
    background: "#F9FAFF",
  },
  cardClass: {
    border: "1px solid #E3E6F4",
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  smallCard: {
    height: 300,
    [theme.breakpoints.between("sm", "md")]: {
      height: 380,
    },
  },
  bigCard: {
    height: 300,
  },
  mobileCard: {
    [theme.breakpoints.down("md")]: {
      height: 555,
    },
    [theme.breakpoints.down("sm")]: {
      height: 700,
    },
    [theme.breakpoints.up("sm")]: {
      height: 470,
    },
  },
  middleCard: {
    height: 233,
  },
  detailedReportCard: {
    height: 220,
    [theme.breakpoints.down("md")]: {
      height: 225,
    },
    [theme.breakpoints.down("sm")]: {
      height: 390,
    },
    [theme.breakpoints.down("xs")]: {
      height: 390,
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  siteScoreheadTitle: {
    fontSize: 14,
    // fontFamily: "Montserrat"
  },
  titleScore: {
    color: theme.palette.text.primary,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  midCard: {
    minHeight: "58.5vh",
    boxShadow: "unset",
  },
  buttonTrue: {
    color: theme.palette.common.green,
    background: theme.palette.graderGraphColors.white,
    padding: "0px 0px",
    fontSize: "0.875rem",
    minWidth: 78,
    boxSizing: "border-box",
    lineHeight: 1.75,
    fontWeight: "500",
    borderRadius: 4,
    fontFamily: "Lato",
    textAlign: "right",
  },
  red: {
    color: theme.palette.graderGraphColors.red,
  },
  yellow: {
    color: theme.palette.graderGraphColors.yellow,
  },
  green: {
    color: theme.palette.graderGraphColors.green,
  },
  titleYellow: {
    color: theme.palette.darKYellow,
  },
  buttonFalse: {
    color: theme.palette.common.red,
    background: theme.palette.graderGraphColors.white,
    padding: "0px 0px",
    fontSize: "0.875rem",
    minWidth: 78,
    boxSizing: "border-box",
    lineHeight: 1.75,
    fontWeight: "500",
    borderRadius: 4,
    fontFamily: "Lato",
    textAlign: "right",
  },
  checkedStyle: {
    color: "#4CAF50",
    fontSize: "1rem",
    padding: "0 0rem",
  },
  crossStyle: {
    color: "#EB3223",
    fontSize: "1rem",
    padding: "0 0rem",
  },
  timeText: {
    fontSize: 14,
    fontFamily: "Lato",
  },
  reportContentStyle: {
    color: "#9BA0A6",
    fontWeight: "normal",
    fontSize: 16,
    fontFamily: "Lato",
    marginLeft: "2%",
  },
  checkBoxContentStyle: {
    color: "#9BA0A6",
    fontWeight: "normal",
    fontSize: 15,
    fontFamily: "Lato",
    verticalAlign: -2,
    marginLeft: -8,
  },
  checkBoxStyles: {
    color: "#4AA459",
    border: "#4AA459",
  },
  meterSection: {
    fontFamily: "Montserrat",
    fontSize: "1.2rem",
    fontWeight: "500",
    important: "true",
  },
  siteScoreTitle: {
    display: "block",
    // justifyContent:"center",
    color: theme.palette.text.primary,
    opacity: "0.5",
  },
  newMainTitle: {
    fontWeight: "bold",
    fontFamily: "Lato",
    fontSize: "20px",
  },
  mainTitleAlignment: {
    marginTop: 10,
    marginBottom: 10,
  },
  preview: {
    minHeight: "100%",
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    border: "1px solid #E3E6F4",
    borderRadius: "6px",
    height: 300,
  },
  previewExternal: {
    minHeight: "94%",
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    border: "1px solid #E3E6F4",
    borderRadius: "6px",
    height: 286,
    [theme.breakpoints.between("sm", "md")]: {
      height: 350,
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    border: "3px solid " + theme.palette.text.primary,
    borderRadius: 6,
  },
  imgContainer: {
    height: "89%",
  },
  gridp: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: 0,
  },
  dStand: {
    marginTop: "-2px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      width: "75%",
    },
  },
  pb: {
    paddingBottom: 0,
  },
  text: { fontSize: "14px" },
  lightGreen: {
    color: theme.palette.speedMeter.lightGreen,
    fontSize: "24px",
  },
  websiteErrorContent: {
    color: "#8F99A7",
    // opacity:"0.5",
    fontWeight: "bold",
  },
  paidSearchErrorContent: {
    color: "#8F99A7",
    fontWeight: "bold",
    fontSize: 14,
  },
  siteScoreMainTitle: {
    fontWeight: "800",
    marginBottom: 3,
  },
  lightRedPinkBg: {
    backgroundColor: theme.palette.lightRedPink,
    fontSize: 16,
    margin: 0,
    width: 280,
  },
});
const mapStateToProps = (state) => ({
  siteGrader: state.siteGrader,
});
const mapDispatchToProps = (dispatch) => ({
  getSiteGraderResult: (domainName, url) =>
    dispatch(getsSiteGraderResult(domainName, url, 0)),
  getsSiteGraderInternalResult: () => dispatch(getsSiteGraderInternalResult()),
  siteGraderReset: () => dispatch(siteGraderReset()),
  seoReportStatusUpdation: (seoReportStatus, clientId) =>
    dispatch(seoReportStatusUpdation(seoReportStatus, clientId)),
  siteGraderPageTrack: (reportType) =>
    dispatch({
      type: SITE_GRADER_PAGE,
      reportType,
    }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(SEOSiteGraderInternalStyles)(SEOSiteGraderInternal)));

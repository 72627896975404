import React, { useEffect } from "react";
import { OrangeTextSize12, CatalinaBlue } from "../../components/typography";
import { PricingButton } from "../../components/buttons.jsx";
import withStyles from "@mui/styles/withStyles";
import { Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import icons from "../../components/icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as types from "../../actions/actionTypes";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import classNames from "classnames";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    paddingTop: 93,
    textAlign: "center",
    backgroundColor: "#EFEFEF",
    boxShadow: "unset",
  },
  paragraphStyle: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 10,
      paddingRight: 13,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 190,
      paddingRight: 183,
    },
  },
  numberStyle: {
    fontSize: 40,
    color: "#F58120",
  },
  moStyle: {
    fontSize: 24,
    color: "#F58120",
  },
  borderStyling: {
    border: "1px solid " + theme.palette.orangeColor,
    borderRadius: 10,
    height: 475,
    // width: 477,
    backgroundColor: "white",
    [theme.breakpoints.down("xl")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.between("sm", "xl")]: {
      height: 575,
    },
  },
  borderOutsidestyle: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 20,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 90,
      paddingRight: 90,
      paddingTop: 70,
      paddingBottom: 90,
    },
  },
  imgstyles: {
    paddingTop: 43.75,
    paddingBottom: 19,

    fontSize: 50,
    fontWeight: 2,
    color: "orange",
    [theme.breakpoints.down("xl")]: {
      paddingTop: 70.75,
      paddingBottom: 19,
    },
    [theme.breakpoints.down("xl")]: {
      paddingTop: 85.75,
      // paddingBottom: 19,
    },
  },
  showdowimgstyle: {
    borderRadius: 32,
    boxShadow: "1px 4px beige",
  },
  buttonstyles: {
    paddingTop: 44,
  },
  bestofferstyle: {
    // [theme.breakpoints.up('xs')]: {
    //    margin: '-4px 0px -126px 195px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     margin: '-6px 0px -126px 195px',
    // },
  },
  btn: {
    width: "70%",
  },
  imgP: {
    position: "relative",
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  imgContainer: {
    marginLeft: "-16px",
    marginTop: "-16px",
    position: "absolute",
    width: "100%",
    textAlign: "right",
  },
  mB: {
    marignBottom: 15,
  },
  // color={"textPrimary"}
  // align={"center"}
  // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
  // style={{ fontWeight: "700" }}
  heading: {
    color: "textPrimary",
    fontWeight: "700",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

function GoogleAdsLogo(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("sm", "xl")
  );

  return (
    <Typography>
      {props.width == "sm" || props.width == "xs"
        ? isTabletResponsive
          ? icons.adwordsNewLogo("10%")
          : icons.adwordsNewLogo("25%")
        : icons.adwordsNewLogo("auto")}
      {/* box-shadow: 0px 5px 3px 2px beige; */}
    </Typography>
  );
}

GoogleAdsLogo.propTypes = {
  width: PropTypes.any,
  tabletResponsive: PropTypes.bool,
};

function PricingPlan(props) {
  const { width, theme, classes } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.pricingPageTrack();
  }, []);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Helmet>
          <title>Pricing Plan | Google Ads Agency Pricing | LXRGuide</title>
          <meta
            name="description"
            content="Start growing your business with our pricing plan including ppc management costs, adwords management rates, and more. Our team of experts are here to help."
          />
        </Helmet>
        <Grid container>
          <Grid item md={12} xs={12}>
            <OrangeTextSize12>OUR PRICING PLAN</OrangeTextSize12>
            <Typography
              className={classes.heading}
              // color={"textPrimary"}
              // align={"center"}
              // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
              // style={{ fontWeight: "700" }}
            >
              Affordable Pricing Plans
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} className={classes.paragraphStyle}>
            <CatalinaBlue>
              <br />
              <br />
              LXRGuide Pricing Plans are tailored for growth marketers and small
              business owners like you. For a low monthly fee, you not only get
              a robust paid search tool, but also the support of an expert
              digital marketer who helps and guides you when you need it.
              Contact our Growth Marketing Specialist to learn how the LXRGuide
              Growth Model can help your business scale profitably.
            </CatalinaBlue>
            <br />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Grid
              container
              justifyContent={"space-around"}
              spacing={2}
              className={classes.borderOutsidestyle}
            >
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                className={classes.borderStyling}
              >
                <div className={classes.imgstyles}>
                  <GoogleAdsLogo width={width} theme={theme} />
                </div>
                <div>
                  <span className={classes.numberStyle}>$ 700</span>
                  <span className={classes.moStyle}>/mo</span>
                </div>
                <CatalinaBlue>
                  <br />
                  ----------OR----------
                  <br />
                  <br />
                  2% of Spend* Whichever is higher
                </CatalinaBlue>
                <div className={classes.buttonstyles}>
                  <PricingButton
                    className={classes.btn}
                    component={Link}
                    to={"/request-demo"}
                  >
                    GET STARTED NOW
                  </PricingButton>
                </div>
                <br />
                <br />
              </Grid>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                className={[classes.imgP, classes.borderStyling]}
              >
                <div className={classes.imgContainer}>{icons.bestOffer}</div>
                <Grid
                  container
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={classes.imgstyles}
                >
                  <Grid item>
                    {width == "sm" || width == "xs"
                      ? icons.adwordsNewLogo("100%")
                      : icons.adwordsNewLogo("auto")}
                  </Grid>
                  <Grid item className={classes.mB}>
                    +
                  </Grid>
                  <Grid item>
                    {width == "sm" || width == "xs"
                      ? icons.microsoftLogoNew("100%")
                      : icons.microsoftLogoNew("auto")}
                  </Grid>
                </Grid>
                <div>
                  <span className={classes.numberStyle}>$ 950</span>
                  <span className={classes.moStyle}>/mo</span>
                </div>
                <CatalinaBlue>
                  <br />
                  ----------OR----------
                  <br />
                  <br />
                  2% of Spend* Whichever is higher
                </CatalinaBlue>

                <div className={classes.buttonstyles}>
                  <PricingButton
                    className={classes.btn}
                    component={Link}
                    to={"/request-demo"}
                  >
                    GET STARTED NOW
                  </PricingButton>
                </div>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*</Grid>*/}
        {/* <Typography>Helllo Arvind LatoBlack</Typography> */}
        {/* <LatoBold>Helllo Arvind LatoBold</LatoBold> */}
        {/* <Typography><b>Helllo Arvind LatoBlack</b></Typography> */}
      </CardContent>
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => ({
  pricingPageTrack: () => dispatch({ type: types.PRICING_PAGE_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(styles, { withTheme: true })(PricingPlan)));

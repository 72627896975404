import * as _ from "lodash";
import { combineReducers } from "redux";
import * as types from "../actions/actionTypes";
import {
  ACTIVITY_REPORT_ID,
  DASHBOARD_NOTIFICATION_ID,
  REPORT_NOTIFICATION_ID,
  TASK_NOTIFICATION_ID,
} from "../actions/actionTypes";
// import {initialState} from '../components/onDemand/view-details-data'
import { dateRangeInitialState } from "../components/admin/templates";
import { getLastDates } from "../components/grader/templates";
import { initialSiteGraderState } from "../components/siteGrader/templates";
import {
  activityReport,
  campaignIdActivityReportMap,
  kpiGraphNavigation,
  taskDataActivityById,
} from "./activityReportData";
import {
  autoPostingDataReducers,
  saveAutoPostingDataReducers,
} from "./autoPostingData";
import { sumUpImpact } from "./common";
import industryInsightsDataReducers from "./industryInsightsData";
import {
  googleAnalyticsURLDataReducers,
  connectionsDataReducer,
  deleteConnectionsDataReducer,
  fetchDataAndStoreEngIdDataReducers,
  fetchPropertiesAndViewsDataReducer,
  fetchGAModelDataReducer,
  fetchGASessionsAndTransactionsDataReducer,
  fetchStatesSessionAndBounceRateDataReducer,
  saveAnalyAccDataReducer,
  fetchSynCheckIDReducer,
  fetchStatusForProgressBarReducer,
  fetchDateRangeForGADataReducers,
  googleAnalyticsBannerServiceReducer,
  
} from "./googleAnalyticsData";
import websiteHealthScoreDataReducers from "./websiteHealthScoreData";
import websiteHealthEmailDataReducers from "./websiteEmailData";
import industryInsightsIndustryDataReducers from "./industryInsightsIndutryData";
import { jumpstart } from "./jumpstartData";
import { paymentHistory } from "./paymentHistory";
import { reportMetric, selectedReportsDateRange } from "./reportsData";
import { accountSettings } from "./settingsData";
import { subscriptionsData } from "./subscriptionsData";
import { userInformation } from "./userData";
import { userProfileInformation } from "./userProfileData";
import OnDemandPromocodeReducer from "./onDemandPromocode";
import {
  deleteGMCAccountReducer,
  fetchGMCAccountReducer,
  deleteBingAdsAccountReducer,
  deleteGleAdsAccountReducer,
  deleteShoppingFeedReducer,
  fetchGMCAuthURLReducer,
  deleteSearchConsoleAccountReducer,
  fetchSearchConsoleAccReducer,
} from "./connectionPageReducers";

import {
  authenticateUserReducer,
  fetchJsAccountsReducer,
  fetchUserInfoReducer,
  fetchJsAccountByIdReducer,
  
  fetchAllUserDataReducer,
  createUserReducer,
  updateUserDetailsReducer,
  deleteUserReducer,

  passwordResetReducer,
  finishPasswordResetReducer,
  updateBusinessDetailsReducer,
  fetchBusinessDetailsByJsAccountIdReducer,
  storeUpdatedCampaignDataReducer,
  fetchCalloutsReducer,
  generateNewCalloutsReducer,
  saveCalloutsReducer,
  fetchSitelinksReducer,
  generateNewSitelinksReducer,
  saveSitelinksReducer,
  fetchCampaignDetailsReducer,
  fetchRsaDetailsReducer,
  saveRsaDetailsReducer,
  generateNewPathsReducer,
  generateNewDescriptionsReducer,
  generateNewHeadlinesReducer,
  fetchKeywordsReducer,
  UpdateKeywordsReducer,
  
  fetchCampaignDetailsByJsAccountIdReducer,
  storeAdgroupDataReducer,
  fetchAdGroupDetailsByCampaignIdReducer,
  fetchAdgroupStructureReducer,
  deleteAdgroupDataReducer,

  createAccountStructureReducer,
  fetchSyncCheckIdReducer,
  submitCampaignFrameworkReducer,
  submitCampaignStructureReducer,
  campaignFrameworkDownloadReducer,fetchAdGroupDetailsByJsAccountIdTESTReducer,
  deleteCampaignStructureReducer
} from "./jumpstartRevampedReducer";

let moment = require("moment");

const initialPerfState = {
  isLoading: false,
  history: [],
  kpiType: "RC",
  kpiTarget: 0.0,
  isConversionAlert: false,
  endDate: moment() + 1,
  graderUser: false,
  kpiTypeUi: "RC",
  isKpiGraphLoading: false,
  currency: "USD",
  isSubscriptions: false,
  isContractSigned: null,
};

function perf(state = initialPerfState, action) {
  switch (action.type) {
    case types.PERF_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PERF_KPI_GRAPH_DATA_REQUESTED:
      return Object.assign({}, state, {
        isKpiGraphLoading: true,
      });

    case types.PERF_DATA_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case types.PERF_DATA_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        isKpiGraphLoading: false,
        ...action.data,
        kpiTypeUi: action.data.kpiType,
        history: [...state.history, ...action.data.history],
      });
    case types.ACCOUNT_TASKS_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    case types.UPDATE_FEEDBACK_STATUS: {
      return Object.assign({}, state, {
        triggerFeedback: false,
      });
    }
    case types.UPDATE_CURRENCY: {
      return Object.assign({}, state, {
        currency: action.currency,
      });
    }
    case types.UPDATE_KPI_TYPE_UI: {
      return Object.assign({}, state, {
        kpiTypeUi: action.KPIType,
      });
    }
    case types.UPDATE_SELECTED_CLIENT_REQUESTED:
    case types.UPDATE_SELECTED_ACCOUNT_RECEIVED: {
      return Object.assign({}, state, {
        ...initialPerfState,
      });
    }
    case types.ACTIVITY_REPORT_RECEIVED: {
      const errorMessage = action.data.errorMessage;
      return Object.assign({}, state, {
        currency: errorMessage == "" ? action.data.currency : state.currency,
      });
    }
    case types.CONTRACT_SIGNED_RECEIVED: {
      const isContractSigned = action.data.isContractSigned;
      return Object.assign({}, state, {
        isContractSigned: isContractSigned,
      });
    }
    case types.ON_DEMAND_PAYMENT_RECEIVED: {
      console.log("subscriptionsData==", action.data.subscriptionsData);
      const subscriptionsData =
        action.data.subscriptionsData != undefined &&
        action.data.subscriptionsData != null &&
        action.data.subscriptionsData.length > 0
          ? action.data.subscriptionsData
          : [];
      return Object.assign({}, state, {
        isSubscriptions: subscriptionsData.length > 0,
      });
    }
    case types.UN_SUBSCRIPTIONS_RECEIVED: {
      return Object.assign({}, state, {
        isSubscriptions: action.data.isSubscriptions,
      });
    }

    default:
      return state;
  }
}

function selectedDateRange(
  state = {
    ...dateRangeInitialState,
  },
  action
) {
  switch (action.type) {
    // case types.SELECT_DATE_RANGE:
    //     console.log("Dfromdate",moment().subtract(7 + 1, 'd').format('YYYY-MM-DD'));
    //     console.log("dtoDate",moment().subtract(1, 'days').format('YYYY-MM-DD'));
    //     return action.dateRanges;
    case types.SELECT_DATE_RANGE:
      let dateRanges = action.dateRanges;
      console.log("selected dreducer=", action.dateRanges);
      return Object.assign({}, state, {
        fromDate: dateRanges.fromDate,
        toDate: dateRanges.toDate,
        itemValue: dateRanges.itemValue,
      });
    case types.RESET_DATE_RANGE:
      return Object.assign({}, state, {
        ...dateRangeInitialState,
      });
    default:
      return state;
  }
}

export function selectedGraphDateRange(
  state = {
    ...dateRangeInitialState,
  },
  action
) {
  switch (action.type) {
    case types.SELECT_GRAPH_DATE_RANGE:
      let dateRanges = action.dateRanges;
      console.log("selected dreducer=", action.dateRanges);
      return Object.assign({}, state, {
        fromDate: dateRanges.fromDate,
        toDate: dateRanges.toDate,
        itemValue: dateRanges.itemValue,
      });
    case types.RESET_GRAPH_DATE_RANGE:
      return Object.assign({}, state, {
        ...dateRangeInitialState,
      });
    default:
      return state;
  }
}

export function hasAdgroupLevel(taskType) {
  switch (taskType / 1) {
    case 1:
    case 10:
    case 11:
    case 15:
    case 17:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 33:
    case 34:
    case 35:
    case 39:
    case 42:
    case 44:
    case 45:
    case 46:
    case 43:
      return false;

    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 12:
    case 13:
    case 14:
    case 16:
    case 19:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
    case 37:
    case 38:
    case 41:
    case 47:
    case 48:
    case 51:
    case 52:
    case 53:
    case 55:
      return true;

    default:
      return false;
  }
}

const configAuditTasks = [13, 12, 14, 22, 11, 10];
const costSavingTasks = [19, 9, 7, 2, 5, 18, 21, 15, 6];
const optimizationTasks = [8, 3, 16, 20, 1, 17, 4, 49];

function taskTypePriority(taskType) {
  if (configAuditTasks.includes(taskType))
    return configAuditTasks.indexOf(taskType);
  if (costSavingTasks.includes(taskType)) return configAuditTasks.length + 1;
  return configAuditTasks.length + 2;
}

export function groupTaskData(taskDataList) {
  console.log("groupTaskDatataskDataList=", taskDataList);

  let groupedTaskData = {
    TaskType: _.chain(taskDataList)
      .groupBy("taskTypeId")
      .mapValues((list, taskTypeId) => ({
        summary: {
          groupId: taskTypeId,
          taskId: list[0].taskId,
          displayStatus: list[0].displayStatus,
          // title: `${list[0].taskName} (${taskTypeId})`,
          title: `${list[0].taskName}`,
          description: list[0].taskDescription,
          impact: sumUpImpact(list),
          totalCount: list.length,
          taskTypePriority: taskTypePriority(taskTypeId / 1),
          timeEstimate: list[0].timeEstimate,

          //========New code for autotaskposting

          actionStatus: list[0].actionStatus,
          actionDate: list[0].actionDate,

          // console.log(list)
          //========end of new code for autotaskposting
        },
        items: _.chain(list)
          .groupBy("campaignId")
          .mapValues((list) =>
            hasAdgroupLevel(list[0].taskTypeId)
              ? _.chain(list)
                  .groupBy("adGroupName")
                  .mapValues((list) => _.map(list, "taskDataId"))
                  .value()
              : _.map(list, "taskDataId")
          )
          .value(),
        dataIdList: _.map(list, "taskDataId"),
      }))
      .value(),

    Campaign: _.chain(taskDataList)
      .filter(
        (task) =>
          task.taskTypeId != 49 &&
          task.taskTypeId != 51 &&
          task.taskTypeId != 54
      )
      .groupBy("campaignName")
      .mapValues((list, campaignName) => ({
        summary: {
          groupId: campaignName,
          title: campaignName,
          // description: "no description",
          impact: sumUpImpact(list),
          totalCount: list.length,
          campaignType: list[0].campaignType,
        },
        items: _.chain(list)
          .groupBy("taskTypeId")
          .mapValues((list) =>
            hasAdgroupLevel(list[0].taskTypeId)
              ? _.chain(list)
                  .groupBy("adGroupName")
                  .mapValues((list) => _.map(list, "taskDataId"))
                  .value()
              : _.map(list, "taskDataId")
          )
          .value(),
        dataIdList: _.map(list, "taskDataId"),
      }))
      .value(),
  };
  // console.log("groupedTaskData = ", groupedTaskData);
  /* This is Commented for campaign level requirement*/
  /*if (groupedTaskData.TaskType[33])
        groupedTaskData.TaskType[33].items = _.flatten(_.values(groupedTaskData.TaskType[33].items));
    if (groupedTaskData.TaskType[34])
        groupedTaskData.TaskType[34].items = _.flatten(_.values(groupedTaskData.TaskType[34].items));*/
  if (groupedTaskData.TaskType[44])
    groupedTaskData.TaskType[44].items = _.flatten(
      _.values(groupedTaskData.TaskType[44].items)
    );
  if (groupedTaskData.TaskType[43])
    groupedTaskData.TaskType[43].items = _.flatten(
      _.values(groupedTaskData.TaskType[43].items)
    );
  if (groupedTaskData.TaskType[57])
    groupedTaskData.TaskType[57].items = _.flatten(
      _.values(groupedTaskData.TaskType[57].items)
    );
  console.log("groupedTaskData==", groupedTaskData);
  return groupedTaskData;
}

function taskDataById(state = {}, action) {
  switch (action.type) {
    case types.PERF_DATA_RECEIVED:
    case types.ACCOUNT_TASKS_RECEIVED: {
      const taskDataList = _.flatMap(action.data.tasks, "taskDataList").map(
        (td) => ({ ...td, selected: td.status === 0 })
      );
      return _.keyBy(taskDataList, "taskDataId");
    }
    case types.UPDATE_TASK_DATA_STATUS: {
      const statuses = action.data;
      console.log("statuses=", statuses);
      console.log("intial state=", state);
      const modifiedTasks = _.mapValues(statuses, (status, id) => ({
        ...state[id],
        status,
        selected: status === 0,
      }));
      let newState = { ...state, ...modifiedTasks };
      return newState;
    }
    case types.UPDATE_TASK_DATA_STATUS_TASK_TYPE_51: {
      const statuses = action.data;
      const modifiedTasks = _.mapValues(statuses, (taskData, id) => ({
        ...state[id],
        status: taskData.status,
        adCopy: taskData.adCopy,
        selected: taskData.status === 0,
      }));
      let newState = { ...state, ...modifiedTasks };
      return newState;
    }
    case types.ON_DEMAND_PAYMENT_RECEIVED: {
      const { taskDataStatusMap } = action.data;
      console.log("taskDataStatusMap=", taskDataStatusMap);
      const modifiedTasks = _.mapValues(taskDataStatusMap, (taskData, id) => ({
        ...state[id],
        status: taskData.status,
        adCopy: taskData.adCopy,
        selected: taskData.status === 0,
      }));
      let newState = { ...state, ...modifiedTasks };
      return newState;
    }
    case types.TOGGLE_SELECTION: {
      const { taskIds, selected } = action.data;
      let modifiedTasks = taskIds.map((id) => ({ ...state[id], selected }));
      modifiedTasks = _.keyBy(modifiedTasks, "taskDataId");
      console.log("selected=", selected);
      console.log("modifiedTasks=", { ...state, ...modifiedTasks }[1408445]);
      return { ...state, ...modifiedTasks };
    }
    case types.SEEN_TASK: {
      const taskIds = action.data;
      let taskData = _.filter(state, (taskData) => taskData.taskId == taskIds);
      taskData = taskData.map((td) => ({ ...td, taskSeenDate: new Date() }));
      taskData = _.keyBy(taskData, "taskDataId");
      return { ...state, ...taskData };
    }

    default:
      return state;
  }
}

function groupedTaskData(
  state = { TaskType: {}, Campaign: {}, flat: [] },
  action
) {
  switch (action.type) {
    case types.PERF_DATA_RECEIVED:
    case types.ACCOUNT_TASKS_RECEIVED: {
      console.log("action.data.tasks=", action.data.tasks);
      const taskDataList = _.flatMap(action.data.tasks, "taskDataList");
      console.log("taskDataList===", taskDataList);
      return { ...groupTaskData(taskDataList), taskDataList };
    }
    case types.UPDATE_TASK_DISPLAY_RECEIVED:
      const { task, displayType } = action;
      let oldTaskTypeMap = state.TaskType;
      let updatedSummary = {
        ...oldTaskTypeMap[task.taskTypeId].summary,
        displayStatus: displayType,
      };
      let newTaskType = {
        summary: updatedSummary,
        items: oldTaskTypeMap[task.taskTypeId].items,
        dataIdList: oldTaskTypeMap[task.taskTypeId].dataIdList,
      };
      console.log("TaskType obj=", state.TaskType);
      console.log("TaskType action=", action);
      console.log("old task updated=", newTaskType);
      console.log("tasktype merge==", {
        ...state.TaskType,
        [task.taskTypeId]: newTaskType,
      });
      return Object.assign({}, state, {
        TaskType: { ...state.TaskType, [task.taskTypeId]: newTaskType },
      });

    default:
      return state;
  }
}

function campaignIdMap(state = {}, action) {
  switch (action.type) {
    case types.PERF_DATA_RECEIVED:
    case types.ACCOUNT_TASKS_RECEIVED:
      return _.chain(action.data.tasks)
        .flatMap("taskDataList")
        .map((td) => _.pick(td, ["campaignId", "campaignName", "campaignType"]))
        .keyBy("campaignId")
        .value();
    default:
      return state;
  }
}

function taskTypeIdMap(state = {}, action) {
  switch (action.type) {
    case types.PERF_DATA_RECEIVED:
    case types.ACCOUNT_TASKS_RECEIVED:
      return _.chain(action.data.tasks)
        .map((task) =>
          _.pick(task, ["taskTypeId", "taskName", "taskDescription"])
        )
        .keyBy("taskTypeId")
        .value();
    default:
      return state;
  }
}

function updatedOnDemand(state = {}, action) {
  switch (action.type) {
    case types.ON_DEMAND_CART_SAVED:
      console.log("data after update", action.data);
    default:
      return state;
  }
}

function miniServiceItemCount(
  //  from below we are setting currentcount in the counter.jsx page
  state = {
    "Ad Group": 0,
    "Ad Copy": 0,
    Sitelink: 0,
    Price: 0,
    "Structure Snippet": 0,
    Callout: 0,
    "Starter Service Pack": 0,
    "Professional Service Pack": 0,
    "Premier Service Pack": 0,
    "Custom Product Feed Audit/Fix": 0,
    "Custom Product Feed Audit/Fix 10": 0,
    "Web Development": 0,
    "SEO Service": 0,
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_MINI_SERVICE_ITEM_COUNT:
      const item = action.data;
      const newState = { ...state, [item.name]: item.counts };
      return newState;
    case types.RESET_CART_COUNT:
      const countsResetState = _.mapValues(state, (v, k) => (v = 0));
      return countsResetState;
    case types.ACCOUNT_STRUCTURE_RECEIVED:
      let itemCounts = action.data.itemCounts;
      if (itemCounts != null) {
        console.log("item counts default load=", itemCounts);
        return { ...state, ...itemCounts };
      } else {
        return state;
      }
    default:
      return state;
  }
}

function inputFroms(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_MINI_SERVICE_ITEM_COUNT:
      const item = action.data;
      const emptyAdgroupInput = (type, key) => ({
        campaign: "",
        adgroupTheme: "",
        businessDetails: "",
        url: "",
        adgroupMap: null,
        type: type,
        label: key,
      });

      const emptyStructureSnippetInput = (type, key) => ({
        campaign: "",
        adgroupTheme: "",
        adgroupMap: null,
        type: type,
        label: key,
      });
      const emptyCommonInput = (type, key) => ({
        campaign: "",
        adgroupTheme: "",
        businessDetails: "",
        adgroupMap: null,
        type: type,
        label: key,
      });

      const getEmptyInput = (type, key) => {
        switch (type) {
          case "Ad Group":
            return emptyAdgroupInput(type, key);
          case "Structure Snippet":
          case "Price":
            return emptyStructureSnippetInput(type, key);
          case "Ad Copy":
          case "Sitelink":
          case "Callout":
            return emptyCommonInput(type, key);
        }
      };
      console.log("prvSteps=", state.steps);

      const itemType = item.name;
      const index = item.counts;
      const key = itemType + index;
      let newState = state;
      if (!state[key]) {
        const value = getEmptyInput(itemType, key);
        newState = { ...state, [key]: value };
      }
      console.log("newSS=", newState);
      return newState;
    case types.UPDATE_MINI_SERVICE_INPUTS:
      const {
        type,
        label,
        campaign,
        adgroupTheme,
        businessDetails,
        url,
        adgroupMap,
        taskDataId,
      } = action.data;
      let inputValues = {
        campaign,
        adgroupTheme,
        businessDetails,
        url,
        adgroupMap,
        type,
        label,
      };
      console.log("type=", type);
      switch (type) {
        case "Structure Snippet":
        case "Price":
          inputValues = { campaign, adgroupTheme, adgroupMap, type, label };
          return { ...state, [label]: inputValues };
        case "Ad Copy":
        case "Sitelink":
        case "Callout":
          inputValues = {
            campaign,
            adgroupTheme,
            businessDetails,
            adgroupMap,
            type,
            label,
            taskDataId,
          };
          console.log("inside form values=", inputValues);
          return { ...state, [label]: inputValues };
        default:
          return { ...state, [label]: inputValues };
      }
    case types.RESET_INPUT_FORMS:
      return (state = {});
    case types.ACCOUNT_STRUCTURE_RECEIVED:
      let formInputs = action.data.inputFroms;
      if (formInputs != null) {
        formInputs = _.keyBy(_.flatten(_.values(formInputs)), "label");
        console.log("load default fromInputs=", formInputs);
        return { ...state, ...formInputs };
      } else {
        return state;
      }

    default:
      return state;
  }
}

/*function cardDetails(state = {
    isLoading: false,
    cardNumber: null
}, action) {
    switch (action.type) {
        case types.CARD_NO_REQUESTED:
            let cardDetailsobj = Object.assign({}, state, {
                isLoading: true
            });
            console.log("CARD_NO_REQUESTED", cardDetailsobj);
            return cardDetailsobj;
        case types.CARD_NO_RECEIVED:
            let cardDetails = Object.assign({}, state, {
                isLoading: false,
                ...action.data,
            });
            console.log("CARD_NO_RECEIVED", cardDetails);
            return cardDetails;
        default:
            return state;
    }

}*/

export function accountStructureDetails(
  state = {
    isLoading: false,
    campaignsMap: null,
    cardDetails: null,
    adGroupList: null,
  },
  action
) {
  switch (action.type) {
    case types.ACCOUNT_STRUCTURE_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.ACCOUNT_STRUCTURE_REQUESTED_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.ACCOUNT_STRUCTURE_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    default:
      return state;
  }
}

export function onDemandPayment(
  state = {
    isLoading: false,
    isPremierServicePackMonthly: false,
    isSEOPackTrimonthly: false,
    payment_status: false,
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.ON_DEMAND_PAYMENT_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.ON_DEMAND_PAYMENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        message: "ON_DEMAND_PAYMENT_FAILED",
      });
    case types.ON_DEMAND_PAYMENT_RECEIVED:
      console.log("payment data in reducer=", action.data);
      const { status, whsStatus, isPremierServicePackMonthly,isSEOPackTrimonthly } = action.data;
      return Object.assign({}, state, {
        isLoading: false,
        isPremierServicePackMonthly: isPremierServicePackMonthly,
        isSEOPackTrimonthly: isSEOPackTrimonthly,
        status: status,
        payment_status: whsStatus,
        message: "ON_DEMAND_PAYMENT_RECEIVED",
      });
    case types.ACCOUNT_STRUCTURE_RECEIVED:
      return Object.assign({}, state, {
        isPremierServicePackMonthly: action.data.isPremierServicePackMonthly,
        isSEOPackTrimonthly: action.data.isSEOPackTrimonthly,

      });
    case types.ON_DEMAND_PAYMENT_STATUS:
      return { ...state, status: null, message: "ON_DEMAND_PAYMENT_STATUS" };
    case types.UPDATE_Premier_PACK_MONTHLY_TOGGLE:
      console.log("UPDATE_Premier_PACK_MONTHLY_TOGGLE");
      return Object.assign({}, state, {
        isPremierServicePackMonthly: action.isPremierServicePackMonthly,
      });
      case types.UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE:
        console.log("UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE");
        return Object.assign({}, state, {
          isSEOPackTrimonthly: action.isSEOPackTrimonthly,
        });
    case types.UN_SUBSCRIPTIONS_RECEIVED:
      console.log("UPDATE_Premier_PACK_MONTHLY_TOGGLE");
      return Object.assign({}, state, {
        isPremierServicePackMonthly: action.isPremierServicePackMonthly,
      });
    default:
      return state;
  }
}

function adminUserTable(
  state = {
    isLoading: false,
    userInfoList: [],
    userNoteMap: {},
    subAdminUserList: [],
    pricingPlanList: [],
    rows: [],
    currentPage: 0,
    pageSize: 20,
    totalCount: 0,
    sorting: [{ columnName: "registrationDate", direction: "desc" }],
    statusFilter: "all",
    channelFilter: -1,
    searchTerm: "",
    // accountStatsMap: {},
    userTypeToggle: false,
    // deleteAlert: false,
  },
  action
) {
  switch (action.type) {
    case types.USER_INFO_REQUESTED:
    // case types.USER_ACCOUNT_REQUESTED:
    case types.UPDATE_USER_NOTE_REQUESTED:
    case types.ADMIN_USER_TABLE_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.ADMIN_PROV_USER_TABLE_DATA_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    // case types.USER_ACCOUNT_FAILED:
    case types.END_USER_JUMPSTART_REQUEST_FAILED:
    case types.GET_USER_REQUEST_FAILED:
    case types.DELETE_USER_REQUEST_FAILED:
    case types.ADMIN_USER_TABLE_DATA_REQUESTED_FAILED:
    case types.END_DISABLE_USER_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.ADMIN_USER_TABLE_DATA_RECEIVED:
      let userInfoList = _.map(action.data.userInfoList, (u) =>
        _.pick(u, [
          "username",
          "emailId",
          "phoneNumber",
          "companyName",
          "userId",
          "pricingPlan",
          "registrationDate",
          "status",
          "advanceRemaining",
          "endDate",
          "neRepresentativeMailId",
          "upsSalesProfessionalName",
          "obfURL",
          "signupURL",
          "userType",
          "type",
          "deleted",
        ])
      );
      // console.log("userInfoList=", userInfoList);
      let rows = [...state.rows];
      let totalCount =
        action.data.totalCount != -1
          ? action.data.totalCount
          : state.totalCount;
      for (var n = 0; n < userInfoList.length; n++) {
        rows[action.startRowId + n] = userInfoList[n];
      }
      console.log("In reducer rows=", rows);
      return Object.assign({}, state, {
        isLoading: false,
        rows,
        ...action.data,
        userInfoList: [...state.userInfoList, ...action.data.userInfoList],

        totalCount: totalCount,
      }); 
      
    case types.ADMIN_PROV_USER_TABLE_DATA_RECEIVED:
      // debugger;
      console.log("reducer data", action);
      let userInfoList1 = _.map(action.data.userInfoList, (u) =>
        _.pick(u, [
          "username",
          "emailId",
          "phoneNumber",
          "companyName",
          "userId",
          "pricingPlan",
          "registrationDate",
          "status",
          "advanceRemaining",
          "endDate",
          "neRepresentativeMailId",
          "upsSalesProfessionalName",
          "obfURL",
          "signupURL",
          "userType",
          "type",
          "deleted",
        ])
      );
      let provUserInfoList = _.map(action.data.provisionalUserList, (u) =>
        _.pick(u, [
          "pvslUserName",
          "pvslEmailAddress",
          "pvslCompany",
          "pvslPhoneNumber",
          "pvslUserId",
          "registrationDate",
          "contactSigned",
          "contractSignedDate",
          "neRepresentativeMailId",
          "upsSalesProfessionalName",
          "deleted",
        ])
      );
      // rows = [...state.rows];
      let rows1 = [...state.rows];
      let totalCount1 =
        action.data.provisionalUserList.length != -1
          ? action.data.provisionalUserList.length
          : 0;
      for (var n = 0; n < provUserInfoList.length; n++) {
        rows1[action.startRowId + n] = provUserInfoList[n];
      }
      console.log("In reducer rows1=", rows1);
      // debugger;
      return Object.assign({}, state, {
        isLoading: false,
        rows1,
        ...action.data,
        userInfoList: [...state.userInfoList, ...action.data.userInfoList],
        provisionalUserList: action.data.provisionalUserList,
        totalCount: totalCount1,
      });

    case types.SET_CURRENT_AND_SORTING_STATE:
      return Object.assign({}, state, {
        isLoading: false,
        currentPage: action.currentPage,
        sorting: action.sorting,
        statusFilter: action.statusFilter,
        channelFilter: action.channelFilter,
        searchTerm: action.searchTerm,
        userTypeToggle: action.userTypeToggle,
        rows: action.rows,
      });
    case types.RESET_TABLE_ROWS:
      return Object.assign({}, state, {
        rows: [],
      });
    case types.UPDATE_USER_NOTE_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        userNoteMap: { ...state.userNoteMap, [action.userId]: action.data[1] },
      });
    case types.GET_USER_NOTE_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    case types.UPDATE_USER_INFORMATION:
      let oldUserArray = state.userInfoList;
      const { userInfo } = action.data;
      let newUsersArray = oldUserArray.map((user) =>
        user.userId == userInfo.userId ? userInfo : user
      );
      console.log("newUserArray=", newUsersArray);
      return Object.assign({}, state, {
        isLoading: false,
        userInfoList: newUsersArray,
      });
    case types.ADD_USER_INFORMATION:
      const { newUserInfo } = action.data;
      const { sorting } = state;
      const oldUserInfoList = state.userInfoList;
      const newUserInfoList = [...oldUserInfoList, newUserInfo];
      state.rows.splice(0, 0, newUserInfo);
      return Object.assign({}, state, {
        isLoading: false,
        userInfoList: newUserInfoList,
        rows: state.rows,
      });
    // case types.USER_ACCOUNT_RECEIVED:
    //     const {accountStatsList} = action.data;
    //     const {userId} = action;
    //     const oldAccountsMap = state.accountStatsMap;
    //     console.log("oldAccountsMap",oldAccountsMap);
    //     console.log("slected stats=",accountStatsList);
    //     console.log("reducer accounts=",{...state.accountStatsMap, [userId]: accountStatsList});
    //     return Object.assign({}, state, {
    //         isLoading: false,
    //         accountStatsMap: {...state.accountStatsMap, [userId]: accountStatsList}
    //     });
    case types.ADMIN_USER_TABLE_SEARCH_TERM:
      return Object.assign({}, state, {
        ...action.data,
      });
    case types.HANDLE_DELETE_USER_ALERT:
      return Object.assign({}, state, {
        deleteAlert: !state.deleteAlert,
      });

    default:
      return state;
  }
}

function taskAnalysis(
  state = {
    tasksMap: {},
    taskAnalysisIsLoading: false,
    selectedPpcId: null,
    selectedAccountId: null,
    isAdmin: false,
  },
  action
) {
  switch (action.type) {
    case types.ACCOUNT_TASKS_REQUESTED:
      return Object.assign({}, state, {
        taskAnalysisIsLoading: true,
      });
    case types.ACCOUNT_TASKS_RECEIVED:
      const { ppcId, accountId } = action;
      const { tasksMap } = state;
      const { tasks } = action.data;
      const accountsMap = { [ppcId]: tasks };
      // const newTaskMapObj = {[userId]: accountsMap};
      // console.log("task analysis map ", {tasksMap, ...newTaskMapObj});
      return Object.assign({}, state, {
        taskAnalysisIsLoading: false,
        tasksMap: { ...tasksMap, ...accountsMap },
        selectedPpcId: ppcId,
        selectedAccountId: accountId,
        isAdmin: true,
      });
    case types.ACCOUNT_TASKS_FAILED:
      return Object.assign({}, state, {
        taskAnalysisIsLoading: false,
      });
    case types.UPDATE_TASK_DISPLAY_RECEIVED:
      const { newTaskList, task, displayType } = action;
      let accountList = state.tasksMap[state.selectedPpcId];
      let newAccountList = accountList.map((task) =>
        task.taskId == task.taskId
          ? {
              ...task,
              displayStatus: displayType,
              taskDataList: task.taskDataList.map((taskData) => ({
                ...taskData,
                displayStatus: displayType,
              })),
            }
          : task
      );

      return Object.assign({}, state, {
        taskAnalysisIsLoading: false,
        tasksMap: { ...state.tasksMap, [state.selectedPpcId]: newAccountList },
      });
    default:
      return state;
  }
}

export function selectedServicePackBytTaskDataIds(state = {}, action) {
  switch (action.type) {
    case types.TOGGLE_SELECTION_FOR_SERVICE_PACK:
      const { taskIds, adCopyObj } = action.data;
      let newState = { ...state, [taskIds]: { ...adCopyObj } };
      return newState;
    case types.RESET_CART_COUNT:
    case types.ON_DEMAND_PAYMENT_RECEIVED:
      return (state = {});
    default:
      return state;
  }
}

export function adminAccountData(
  state = {
    isLoading: false,
    accountStatsMap: [],
  },
  action
) {
  switch (action.type) {
    case types.USER_ACCOUNT_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.USER_ACCOUNT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.USER_ACCOUNT_RECEIVED:
      const { accountStatsList } = action.data;
      const { userId } = action;
      const oldAccountsMap = state.accountStatsMap;
      let newAccountStatsMap = _.keyBy(accountStatsList, "ppcId");
      // let newUserList=[]:newAccountStatsMap
      return Object.assign({}, state, {
        isLoading: false,
        accountStatsMap: {
          ...state.accountStatsMap,
          [userId]: newAccountStatsMap,
        },
      });
    default:
      return state;
  }
}

export function googleAdsPerformance(
  state = {
    isLoading: false,
    selectedDate: null,
    lastDates: getLastDates(),
    integrationProgressBar: 0,
    accountStatsData: null,
    integrationMessage: null,
    graderError: null,
    // wastedSpend:0,
  },
  action
) {
  switch (action.type) {
    case types.GRADER_INTEGRATION_STATUS_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GOOGLE_ADS_PERFORMANCE_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.GRADER_INTEGRATION_STATUS_FAILED:
    case types.GOOGLE_ADS_PERFORMANCE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        graderError: "Something went wrong please try again",
      });
    case types.GOOGLE_ADS_PERFORMANCE_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    case types.GRADER_INTEGRATION_STATUS_RECEIVED:
    case types.UPDATE_SELECTED_DATE:
      return Object.assign({}, state, {
        ...action.data,
      });
    default:
      return state;
  }
}

function keywordHierarchyTaskState(
  state = {
    isLoading: false,
    isAccessStatus: null,
    customAlert: false,
    taskId: null,
    taskDataId: null,
  },
  action
) {
  switch (action.type) {
    case types.KEYWORD_HIERARCHY_STATUS_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.KEYWORD_HIERARCHY_STATUS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.KEYWORD_HIERARCHY_STATUS_RECEIVED:
      // let newUserList=[]:newAccountStatsMap
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    case types.UPDATE_KEYWORD_HIERARCHY:
      return Object.assign({}, state, {
        ...action,
      });
    case types.TOGGLE_IS_ACCESS:
      // let newUserList=[]:newAccountStatsMap
      return Object.assign({}, state, {
        isAccessStatus: !state.isAccessStatus,
      });
    default:
      return state;
  }
}

function siteGrader(
  state = {
    isLoading: false,
    ...initialSiteGraderState,
  },
  action
) {
  switch (action.type) {
    case types.SITE_GRADER_RESULT_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SITE_GRADER_URL_INFO_REQUESTED:
      return Object.assign({}, state, {
        urlInfoLoading: true,
      });
    case types.SITE_GRADER_RESULT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.SITE_GRADER_URL_INFO_FAILED:
      return Object.assign({}, state, {
        urlInfoLoading: false,
        serverError: "Something went wrong",
      });
    case types.SET_SITE_GRADER_ERROR:
      return Object.assign({}, state, {
        serverError: action.data.serverError,
      });
    case types.SITE_GRADER_URL_INFO_RECEIVED:
      console.log("SITE_GRADER_URL_INFO_RECEIVED=", action.data);
      return Object.assign({}, state, {
        urlInfoLoading: false,
        threadStarted: action.data.serverError == "" ? true : false,
        ...action.data,
      });
    case types.SITE_GRADER_RESULT_RECEIVED:
      console.log("action-", action.data);
      const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
      if (useDummyData == true) {
        return Object.assign({}, state, {
          isLoading: false,
          /*serverError: action.data.serverError,
                    lxrSeoDomainURL: action.data.lxrSeoDomainURL,
                    ...action.data.webStats,*/
          ...action.data,
        });
      } else {
        return Object.assign({}, state, {
          isLoading: false,
          serverError: action.data.serverError,
          lxrSeoDomainURL: action.data.lxrSeoDomainURL,
          seoReportStatus: action.data.seoReportStatus
            ? action.data.seoReportStatus
            : false,
          ...action.data.webStats,
        });
      }
    case types.SITE_GRADER_RESET:
      console.log("reset in process");
      return Object.assign({}, state, {
        isLoading: false,
        ...initialSiteGraderState,
      });
    case types.SITE_GRADER_SEO_REPORT_STATUS_REQUESTED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.SITE_GRADER_SEO_REPORT_STATUS_RECEIVED:
      console.log("SITE_GRADER_SEO_REPORT_STATUS_RECEIVED=", action.data);
      return Object.assign({}, state, {
        isLoading: false,
        seoReportStatus:
          action.data.clientUpdateCheckError == ""
            ? !state.seoReportStatus
            : state.seoReportStatus,
        ...action.data,
      });
    case types.SITE_GRADER_SEO_REPORT_STATUS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        clientUpdateCheckError: "Something went wrong",
      });

    default:
      return state;
  }
}

function siteGraderServiceDetails(
  state = {
    isLoading: false,
    serverError: "",
    isEmailValidLoading: false,
    selectedPackage: null,
    activeStep: 0,
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_SELECTED_SEO_PACKAGE:
      return Object.assign({}, state, {
        isLoading: true,
        selectedPackage: action.selectedPackage,
      });
    case types.UPDATE_ACTIVE_STEP_SEO_DETAILS:
      return Object.assign({}, state, {
        isLoading: true,
        activeStep: action.activeStep,
      });
    case types.SAVE_SEO_USER_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

function SEOUserServices(
  state = {
    userServices: null,
    errorMessage: null,
    isLoading: false,
    cardDetails: null,
    domainUrl: null,
    emailId: null,
  },
  action
) {
  switch (action.type) {
    case types.SEO_USER_SERVICES_DETAILS_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.SEO_USER_SERVICES_DETAILS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    case types.SEO_USER_SERVICES_DETAILS_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
      });
    default:
      return state;
  }
}

function userInfoSEOInternal(
  state = {
    errorMessage: null,
    isLoading: false,
    cardDetails: null,
    domainUrl: null,
    emailId: null,
  },
  action
) {
  switch (action.type) {
    case types.SITE_GRADER_RESULT_RECEIVED:
      return Object.assign({}, state, {
        isLoading: true,
        cardDetails: action.data.cardDetails,
      });
    case types.USER_PAYMENT_REQUESTED:
      console.log("reducer=", action.data);
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.USER_PAYMENT_RECEIVED:
      console.log("reducer=", action.data);
      return Object.assign({}, state, {
        isLoading: true,
        cardDetails: action.data.cardDetails,
      });
    case types.USER_PAYMENT_FAILED:
      console.log("reducer=", action.data);
      return Object.assign({}, state, {
        isLoading: true,
      });
    default:
      return state;
  }
}

function dashboard(
  state = {
    viewValue: 0,
  },
  action
) {
  switch (action.type) {
    case types.DASHBOARD_VIEW_VALUE_UPDATED:
      return Object.assign({}, state, {
        viewValue: action.viewValue,
      });
    default:
      return state;
  }
}

function header(
  state = {
    open: false,
    anchorEl: null,
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_DRAWER:
      return Object.assign({}, state, {
        open: action.value,
      });
    case types.UPDATE_MENU:
      return Object.assign({}, state, {
        anchorEl: action.anchorEl,
      });
    case types.TOGGLE_DRAWER:
      return Object.assign({}, state, {
        open: !state.open,
      });
    case types.RESET_MENU:
      return Object.assign({}, state, {
        anchorEl: null,
      });
    default:
      return state;
  }
}

function leadGeneration(
  state = {
    emailId: "",
    website: "",
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_LEAD_GENERATION:
      return Object.assign({}, state, {
        emailId: action.data.emailId,
        website: action.data.website,
      });
    default:
      return state;
  }
}

export function scrollHandler(
  state = {
    isProgress: false,
  },
  action
) {
  switch (action.type) {
    case types.SCROLL_HANDLER_START:
      return Object.assign({}, state, {
        isProgress: true,
      });
    case types.SCROLL_HANDLER_ENDED:
      return Object.assign({}, state, {
        isProgress: false,
      });
    default:
      return state;
  }
}

export function productTour(
  state = {
    welcomeDialog: false,
    thankYouDialog: false,
    isProgress: false,
    dashboardTour: false,
    reportsTour: false,
    taskListTour: false,
    firstTaskTour: false,
    productTourClose: false,
    activityReportTour: false,
    // tourEnd: false,
    // popUp: false,
  },
  action
) {
  switch (action.type) {
    case types.TOGGLE_WELCOME_BANNER:
      console.log("action.type==", action.type);
      return Object.assign({}, state, {
        welcomeDialog: !state.welcomeDialog,
        isProgress: true,
      });
    case types.CANCEL_WELCOME_BANNER:
      return Object.assign({}, state, {
        welcomeDialog: false,
        isProgress: false,
      });
    case types.TOGGLE_THANK_YOU_BANNER:
      return Object.assign({}, state, {
        thankYouDialog: !state.thankYouDialog,
        productTourClose: false,
        isProgress: false,
      });
    case types.ON_PRODUCT_TOUR_CLOSE:
      return Object.assign({}, state, {
        productTourClose: true,
        isProgress: false,
      });
    case types.ON_PRODUCT_TOUR_CLOSE_CROSS:
      return Object.assign({}, state, {
        productTourClose: false,
        isProgress: false,
      });
    case types.PERF_DATA_RECEIVED:
      return Object.assign({}, state, {
        ...action.data.dynamicTour,
      });
    case types.UPDATE_PRODUCT_TOUR_TRACK:
      switch (action.pageId) {
        case DASHBOARD_NOTIFICATION_ID:
          return Object.assign({}, state, {
            dashboardTour: false,
          });
        case REPORT_NOTIFICATION_ID:
          return Object.assign({}, state, {
            reportsTour: false,
          });
        case TASK_NOTIFICATION_ID:
          return Object.assign({}, state, {
            taskListTour: false,
          });
        case ACTIVITY_REPORT_ID:
          return Object.assign({}, state, {
            activityReportTour: false,
          });
        default:
          return null;
      }

    default:
      return state;
  }
}

//for the martech services set isMarTech: false
//for the holiday banner services set isMarTech: trues
function martechServices(
  state = {
    isMarTech: false,
    // below is for the holiday banner
    isShowed: false,
  },
  action
) {
  console.log("testing martechServices() =+=+> ");

  switch (action.type) {
    case types.OPEN_MARTCH:
      return Object.assign({}, state, {
        isMarTech: true,
        // below is for the holiday banner
        // isShowed: true,
      });
    case types.CLOSE_MARTCH:
      return Object.assign({}, state, {
        isMarTech: false,
      });
    default:
      return state;
  }
}

function generateRSAadCopyReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.GENERATE_RSA_ADCOPY_REQUESTED:
      return Object.assign({}, state, {});
    case types.GENERATE_RSA_ADCOPY_RECEIVED:
      let { data } = action;
      console.log("GENERATE_RSA_ADCOPY_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
      });
    case types.GENERATE_RSA_ADCOPY_FAILED:
      return Object.assign({}, state, {
        message: "GENERATE_RSA_ADCOPY_FAILED",
      });
    default:
      console.log("The state is ", state);
      return state;
  }
}

function fetchRSAadCopyReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_RSA_DATA_REQUESTED:
      return Object.assign({}, state, {});
    case types.FETCH_RSA_DATA_RECEIVED:
      let { data } = action;
      console.log("FETCH_RSA_DATA_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
      });
    case types.FETCH_RSA_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_RSA_DATA_FAILED",
      });
    default:
      console.log("The state is ", state);
      return state;
  }
}

function saveRSAadCopyReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SAVE_RSA_DATA_REQUESTED:
      return Object.assign({}, state, {});
    case types.SAVE_RSA_DATA_RECEIVED:
      let { data } = action;
      console.log("SAVE_RSA_DATA_REQUESTED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
      });
    case types.SAVE_RSA_DATA_FAILED:
      return Object.assign({}, state, {
        message: "SAVE_RSA_DATA_FAILED",
      });
    default:
      console.log("The state is ", state);
      return state;
  }
}

function fetchWHSExpertDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.WHS_EXPERT_DATA_REQUESTED:
      return Object.assign({}, state, {});
    case types.WHS_EXPERT_DATA_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
      });
    case types.WHS_EXPERT_DATA_FAILED:
      return Object.assign({}, state, {
        message: "WHS_EXPERT_DATA_FAILED",
      });
    default:
      console.log("The state is ", state);
      return state;
  }
}

function refreshWHSDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.WHS_REFRESH_DATA_REQUESTED:
      return Object.assign({}, state, {});
    case types.WHS_REFRESH_DATA_RECEIVED:
      let { data } = action;
      console.log("WHS_REFRESH_DATA_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "WHS_REFRESH_DATA_RECEIVED",
      });
    case types.WHS_REFRESH_DATA_FAILED:
      return Object.assign({}, state, {
        message: "WHS_REFRESH_DATA_FAILED",
      });
    default:
      console.log("The state is ", state);
      return state;
  }
}

function fetchOnDemandPackagesReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.ON_DEMAND_PACKAGE_REQUESTED:
      return Object.assign({}, state, {});
    case types.ON_DEMAND_PACKAGE_RECEIVED:
      let { data } = action;
      console.log("ON_DEMAND_PACKAGE_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "ON_DEMAND_PACKAGE_RECEIVED",
      });
    case types.ON_DEMAND_PACKAGE_FAILED:
      return Object.assign({}, state, {
        message: "ON_DEMAND_PACKAGE_FAILED",
      });
    default:
      console.log("The state is ", state);
      return state;
  }
}

function websiteHealthRefreshStatusReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.WHS_REFRESH_DOWNLOAD_STATUS_REQUESTED:
      return Object.assign({}, state, {
        message: "WHS_REFRESH_DOWNLOAD_STATUS_REQUESTED",
      });
    case types.WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED:
      let { data } = action;
      console.log("WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED",
      });
    case types.WHS_REFRESH_DOWNLOAD_STATUS_FAILED:
      return Object.assign({}, state, {
        message: "WHS_REFRESH_DOWNLOAD_STATUS_FAILED",
      });
    default:
      return state;
  }
}
function recordWhsUserLogReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.WHS_USER_LOG_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "WHS_USER_LOG_DATA_REQUESTED",
      });
    case types.WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED:
      let { data } = action;
      console.log("WHS_USER_LOG_DATA_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "WHS_USER_LOG_DATA_RECEIVED",
      });
    case types.WHS_REFRESH_DOWNLOAD_STATUS_FAILED:
      return Object.assign({}, state, {
        message: "WHS_USER_LOG_DATA_FAILED",
      });
    default:
      return state;
  }
}

function WHSExternalUserDetailsReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.WHS_EXTERNAL_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "WHS_EXTERNAL_DATA_REQUESTED",
      });
    case types.WHS_EXTERNAL_DATA_RECEIVED:
      let { data } = action;
      console.log("WHS_EXTERNAL_DATA_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "WHS_EXTERNAL_DATA_RECEIVED",
      });
    case types.WHS_EXTERNAL_DATA_FAILED:
      return Object.assign({}, state, {
        message: "WHS_EXTERNAL_DATA_FAILED",
      });
    default:
      return state;
  }
}

function fetchSystemMaintenanceBannerDetailsReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.SYSTEM_MAINTENANCE_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "SYSTEM_MAINTENANCE_DATA_REQUESTED",
      });
    case types.SYSTEM_MAINTENANCE_DATA_RECEIVED:
      let { data } = action;
      console.log("SYSTEM_MAINTENANCE_DATA_RECEIVED in reducers is ", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "SYSTEM_MAINTENANCE_DATA_RECEIVED",
      });
    case types.SYSTEM_MAINTENANCE_DATA_FAILED:
      return Object.assign({}, state, {
        message: "SYSTEM_MAINTENANCE_DATA_FAILED",
      });
    default:
      return state;
  }
}

export default combineReducers({
  saveRSAadCopyReducer,
  fetchRSAadCopyReducer,
  generateRSAadCopyReducer,
  perf,
  groupedTaskData,
  taskDataById,
  campaignIdMap,
  taskTypeIdMap,
  selectedDateRange,
  updatedOnDemand,
  miniServiceItemCount,
  inputFroms,
  accountStructureDetails,
  onDemandPayment,
  adminUserTable,
  taskAnalysis,
  adminAccountData,
  selectedServicePackBytTaskDataIds,
  googleAdsPerformance,
  keywordHierarchyTaskState,
  siteGrader,
  siteGraderServiceDetails,
  SEOUserServices,
  userInfoSEOInternal,
  selectedGraphDateRange,
  dashboard,
  userInformation,
  header,
  accountSettings,
  jumpstart,
  activityReport,
  paymentHistory,
  taskDataActivityById,
  campaignIdActivityReportMap,
  selectedReportsDateRange,
  reportMetric,
  userProfileInformation,
  leadGeneration,
  martechServices,
  scrollHandler,
  productTour,
  kpiGraphNavigation,
  subscriptionsData,
  autoPostingDataReducers,
  saveAutoPostingDataReducers,
  industryInsightsDataReducers,
  industryInsightsIndustryDataReducers,
  googleAnalyticsURLDataReducers,
  fetchDataAndStoreEngIdDataReducers,
  fetchPropertiesAndViewsDataReducer,
  fetchGAModelDataReducer,
  fetchGASessionsAndTransactionsDataReducer,
  fetchStatesSessionAndBounceRateDataReducer,
  connectionsDataReducer,
  deleteConnectionsDataReducer,
  saveAnalyAccDataReducer,
  fetchSynCheckIDReducer,
  fetchStatusForProgressBarReducer,
  fetchDateRangeForGADataReducers,
  googleAnalyticsBannerServiceReducer,
  websiteHealthScoreDataReducers,
  websiteHealthEmailDataReducers,
  fetchWHSExpertDataReducer,
  refreshWHSDataReducer,
  fetchOnDemandPackagesReducer,
  websiteHealthRefreshStatusReducer,
  recordWhsUserLogReducer,
  WHSExternalUserDetailsReducer,
  OnDemandPromocodeReducer,
  deleteGMCAccountReducer,
  deleteBingAdsAccountReducer,
  deleteGleAdsAccountReducer,
  deleteShoppingFeedReducer,
  fetchGMCAuthURLReducer,
  fetchGMCAccountReducer,
  authenticateUserReducer,
  fetchJsAccountsReducer,
  fetchUserInfoReducer,
  fetchJsAccountByIdReducer,
  fetchAllUserDataReducer,
  createUserReducer,
  passwordResetReducer,
  finishPasswordResetReducer,
  updateBusinessDetailsReducer,
  fetchBusinessDetailsByJsAccountIdReducer,
  storeUpdatedCampaignDataReducer,
  fetchCalloutsReducer,
  generateNewCalloutsReducer,
  saveCalloutsReducer,
  fetchSitelinksReducer,
  generateNewSitelinksReducer,
  saveSitelinksReducer,
  fetchCampaignDetailsReducer,
  fetchRsaDetailsReducer,
  saveRsaDetailsReducer,
  generateNewPathsReducer,
  generateNewDescriptionsReducer,
  generateNewHeadlinesReducer,
  fetchKeywordsReducer,
  UpdateKeywordsReducer,
  fetchCampaignDetailsByJsAccountIdReducer,
  storeAdgroupDataReducer,
  fetchAdGroupDetailsByCampaignIdReducer,
  fetchAdgroupStructureReducer,
  deleteAdgroupDataReducer,
  deleteUserReducer,
  updateUserDetailsReducer,
  createAccountStructureReducer,
  fetchSyncCheckIdReducer,
  submitCampaignFrameworkReducer,
  submitCampaignStructureReducer,
  campaignFrameworkDownloadReducer,
  fetchAdGroupDetailsByJsAccountIdTESTReducer,
  deleteSearchConsoleAccountReducer,
  fetchSearchConsoleAccReducer,
  fetchSystemMaintenanceBannerDetailsReducer,
  deleteCampaignStructureReducer
});

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormLabel, RadioGroup } from "@mui/material/";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material/";
import { CardActions, CardHeader, Paper, Radio } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../../components/icons";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material/index";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  NewOrangeSaveContainedBtn,
  NewTextRectOrangeTextBtn,
} from "../../components/buttons";
import { UpdateUserProfileDetails } from "../../actions/fetchingUserData";
import MySnackbarWrapper from "../snackbars";
import { ErrorTypography } from "../../components/typography";
import { buyHolidayPackage } from "../../actions";
import { connect } from "react-redux";
import { HOLIDAY_PACKAGE_TRACK } from "../../actions/actionTypes";
import HolidayParagraph from "../../NewInterfacePages/paragraph/holidayParagraph";

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    flexDirection: "row",
  },
  card: {
    boxShadow: "unset",
    background: "unset",
  },
  paper: {
    height: 421,
    [theme.breakpoints.down("xl")]: {
      overflowY: "scroll",
      height: 450,
    },
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
  },
});

//old content for the holiday package.
// const content = [
//   "1 hour holiday strategic planning discussion with a PPC certified search strategist.",
//   "Streamline account set up for maximizing bang for your buck.",
//   "Build new holiday-specific campaigns to drive profitable orders.",
//   "Craft custom ad copies based on LXRGuide's best practices.",
//   "Provide landing page recommendations to improve conversion rate.",
//   "Audit merchant center feed for errors and provide detailed guidelines to fix any issues.",
//   "Automated site and account alerts to ensure your ads are always on.",
//   "Regular checks and active adjustments of campaigns bids and budgets by bid optimization specialists.",
// ];

const content = [
  "1-hour strategic holiday planning session with a certified PPC search strategist.",
  "Streamlined account setup to maximize your advertising budget.",
  "Development of holiday-specific campaigns focused on driving profitable orders.",
  "Creation of custom ad copy using LXRGuide's proven best practices.",
  "Develop and execute promotional ads aligned with the holiday marketing calendar.",
  "Merchant Center feed audit to identify and resolve errors with detailed guidelines.",
  "Automated account alerts to ensure continuous ad performance.",
  "Regular monitoring and optimization of campaign bids and budgets by bid optimization specialists.",
];
const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
    paddingLeft: 0,
  },
}))(ListItem);
const CustomListItemText = withStyles((theme) => ({
  root: {
    color: "inherit",
    fontWeight: "500",
  },
}))(ListItemText);
const CustomDialogContentText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 16,
    // minWidth:200,
    // minHeight:100
  },
}))(DialogContentText);

class HolidayPackage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: "1",
      alert: false,
      message: "",
      variantType: "info",
      planState: "paying",
      isPaying: false,
      commonError: "",
    };
  }

  componentDidMount() {
    document.title = "LXRGuide - Holiday Growth Booster Package";
    this.props.holidayPackageTrack();
  }

  handleChange = (event) => {
    this.setState({ selectedPlan: event.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      dialog: true,
      planState: "paying",
    });
  };
  handleAgreeSubmit = async () => {
    console.log("this.state.selectedPlan===>",this.state.selectedPlan);
    this.setState({
      isPaying: true,
    });
    let response = await this.props.buyHolidayPackage(this.state.selectedPlan);
    console.log("response=", response);
    if (
      response !== null &&
      response &&
      response.errorMsg &&
      response.errorMsg != ""
    ) {
      this.setState({
        isPaying: false,
        commonError: response.errorMsg,
      });
    } else {
      this.setState({
        // dialog: false,
        commonError: "",
        planState: "afterPayment",
        isPaying: false,
      });
    }
  };
  handleClose = () => {
    this.setState({ dialog: false, planState: "", commonError: "" });
  };
  handleCloseAlert = async () => {
    await this.setState({ alert: false });
  };

  render() {
    const { selectedPlan, isPaying, planState, commonError } = this.state;
    const { classes } = this.props;
    return (
      <Card className={classes.card} style={{ zoom: "90%" }}>
        <HolidayParagraph
          para={
            "Auto apply enables automatic posting of LXRGuide recommendations to your Google Ads account. " +
            "By selecting the check box next to the specified tasks, you agree to participate in implementing tasks automatically." +
            " In enabling auto apply, LXRGuide automatically implements suggestions as necessary, saving you time and increasing the effectiveness of your Google Ads account." +
            "Uncheck any of the tasks you do not wish to update automatically." +
            " Changes can be made at any time." +
            " Click save to update your selections."
          }
        />
        <CardContent style={{ marginLeft: "72px", marginRight: "72px" }}>
          {/*<br/>*/}
          <Grid container justifyContent={"flex-start"}>
            <Grid item>
              <FormControl className={classes.formControl}>
                <RadioGroup
                  style={{ flexDirection: "row" }}
                  name="plan"
                  className={classes.group}
                  value={selectedPlan}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value={"1"}
                    label="1 Month"
                    labelPlacement="end"
                    control={
                      <Radio
                        checkedIcon={icons.orangeChecked}
                        icon={icons.unCheckedNew}
                        color="primary"
                      />
                    }
                  />
                  <FormControlLabel
                    value={"2"}
                    label="2 Months (save $400)"
                    labelPlacement="end"
                    control={
                      <Radio
                        checkedIcon={icons.orangeChecked}
                        icon={icons.unCheckedNew}
                        color="primary"
                      />
                    }
                  />
                  <FormControlLabel
                    value={"3"}
                    label="3 Months (save $1,100)"
                    labelPlacement="end"
                    control={
                      <Radio
                        checkedIcon={icons.orangeChecked}
                        icon={icons.unCheckedNew}
                        color="primary"
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Paper className={classes.paper}>
                <Card className={classes.card}>
                  <form onSubmit={this.handleSubmit}>
                    <CardHeader title={<b>Holiday Growth Booster Package</b>} />
                    <CardContent>
                      {content.map((value, index) => {
                        return (
                          <CustomListItem>
                            <ListItemIcon
                              style={{ margin: 0, justifyContent: "center" }}
                            >
                              {icons.dot}
                            </ListItemIcon>
                            <CustomListItemText>{value}</CustomListItemText>
                          </CustomListItem>
                        );
                      })}
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        style={{ paddingLeft: 10 }}
                      >
                        <Grid item md={3}>
                          <Typography variant={"h2"} color={"textPrimary"}>
                            <b>
                              {selectedPlan == "1" ? ("$2,200 for 1 month") : selectedPlan == "2" ? ("$4,000 for 2 months") : ("$5,500 for 3 months")}
                            </b>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <NewOrangeSaveContainedBtn type={"submit"}>
                            Buy Now
                          </NewOrangeSaveContainedBtn>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </form>
                </Card>
              </Paper>
              <Dialog
                open={this.state.dialog}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={isPaying ? false : true}
                maxWidth={isPaying ? false : "sm"}
              >
                {isPaying ? (
                  <DialogContent>
                    <CustomDialogContentText>
                      <CircularProgress />
                    </CustomDialogContentText>
                  </DialogContent>
                ) : (
                  <Fragment>
                    {planState == "paying" &&
                      this.getPayingDetails(commonError, selectedPlan)}
                    {planState == "afterPayment" &&
                      this.getPayedDetails(commonError, selectedPlan)}
                  </Fragment>
                )}
              </Dialog>
              {/* <MySnackbarWrapper open={this.state.alert}
                                               onClose={this.handleCloseAlert}
                                               message={this.state.message}
                                               verticalAlign={"top"}
                                               horizontalAlign={"right"}
                                               duration={6000}
                                               variant={this.state.variantType}
                            />*/}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  getPayingDetails(commonError, selectedPlan) {
    return (
      <div>
        <DialogTitle>{<b>Holiday Package</b>}</DialogTitle>
        <DialogContent>
          {commonError != "" ? (
            <ErrorTypography>{commonError}</ErrorTypography>
          ) : null}
          {commonError == "" ? (
            <Typography variant={"body1"} color={"textPrimary"}>
              You will be charged {selectedPlan == "2" ? "$4,000" : selectedPlan == "1" ? "$2,200" : "$5,500"}{" "}
              from existing Credit Card.
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          {commonError != "" ? (
            <NewTextRectOrangeTextBtn
              onClick={this.handleClose}
              color="primary"
            >
              OK
            </NewTextRectOrangeTextBtn>
          ) : (
            <Fragment>
              <NewTextRectOrangeTextBtn
                onClick={this.handleClose}
                color="primary"
              >
                Cancel
              </NewTextRectOrangeTextBtn>
              <NewTextRectOrangeTextBtn
                onClick={this.handleAgreeSubmit}
                autoFocus
              >
                Continue
              </NewTextRectOrangeTextBtn>
            </Fragment>
          )}
        </DialogActions>
      </div>
    );
  }

  getPayedDetails(commonError, selectedPlan) {
    return (
      <div>
        <DialogTitle>{<b>Payment Successful</b>}</DialogTitle>
        <DialogContent>
          <Typography variant={"body1"} color={"textPrimary"}>
            {" "}
            Your Payment of {selectedPlan == "2" ? "$4,000" : selectedPlan == "2" ? "$2,200":"$5,500"} is
            Successful.
          </Typography>
        </DialogContent>
        <DialogActions>
          <NewTextRectOrangeTextBtn onClick={this.handleClose} color="primary">
            Ok
          </NewTextRectOrangeTextBtn>
        </DialogActions>
      </div>
    );
  }
}

HolidayPackage.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  buyHolidayPackage: (selectedPlan) =>
    dispatch(buyHolidayPackage(selectedPlan)),
  holidayPackageTrack: () =>
    dispatch({
      type: HOLIDAY_PACKAGE_TRACK,
    }),
  // HOLIDAY_PACKAGE_TRACK
});
export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(HolidayPackage));

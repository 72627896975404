import * as types from "../actions/actionTypes";

export function authenticateUserReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_AUTHENTICATE_USER_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_AUTHENTICATE_USER_REQUESTED",
      });
    case types.JS_AUTHENTICATE_USER_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_AUTHENTICATE_USER_RECEIVED",
      });
    case types.JS_AUTHENTICATE_USER_FAILED:
      return Object.assign({}, state, {
        message: "JS_AUTHENTICATE_USER_FAILED",
      });
    default:
      return state;
  }
}

export function fetchJsAccountsReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_ACCOUNTS_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_ACCOUNTS_REQUESTED",
      });
    case types.JS_ACCOUNTS_RECEIVED:

      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_ACCOUNTS_RECEIVED",
      });
    case types.JS_ACCOUNTS_FAILED:
      return Object.assign({}, state, {
        message: "JS_ACCOUNTS_FAILED",
      });
    default:
      return state;
  }
}

export function fetchUserInfoReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_USER_INFO_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_USER_INFO_REQUESTED",
      });
    case types.JS_USER_INFO_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_USER_INFO_RECEIVED",
      });
    case types.JS_USER_INFO_FAILED:
      return Object.assign({}, state, {
        message: "JS_USER_INFO_FAILED",
      });
    default:
      return state;
  }
}

export function fetchAllUserDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_ALL_USER_DETAILS_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_ALL_USER_DETAILS_REQUESTED",
      });
    case types.JS_ALL_USER_DETAILS_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_ALL_USER_DETAILS_RECEIVED",
      });
    case types.JS_ALL_USER_DETAILS_FAILED:
      return Object.assign({}, state, {
        message: "JS_ALL_USER_DETAILS_FAILED",
      });
    default:
      return state;
  }
}

export function fetchJsAccountByIdReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_ACCOUNT_BY_ID_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_ACCOUNT_BY_ID_REQUESTED",
      });
    case types.JS_ACCOUNT_BY_ID_RECEIVED:

      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_ACCOUNT_BY_ID_RECEIVED",
      });
    case types.JS_ACCOUNT_BY_ID_FAILED:
      return Object.assign({}, state, {
        message: "JS_ACCOUNT_BY_ID_FAILED",
      });
    default:
      return state;
  }
}

export function createUserReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_ADD_USER_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_ADD_USER_REQUESTED",
      });
    case types.JS_ADD_USER_RECEIVED:

      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_ADD_USER_RECEIVED",
      });
    case types.JS_ADD_USER_FAILED:
      return Object.assign({}, state, {
        message: "JS_ADD_USER_FAILED",
      });
    default:
      return state;
  }
}

export function passwordResetReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.PASSWORD_CHANGE_REQUESTED:
      return Object.assign({}, state, {
        message: "PASSWORD_CHANGE_REQUESTED",
      });
    case types.PASSWORD_CHANGE_RECEIVED:

      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "PASSWORD_CHANGE_RECEIVED",
      });
    case types.PAYMENT_DETAILS_FAILED:
      return Object.assign({}, state, {
        message: "PAYMENT_DETAILS_FAILED",
      });
    default:
      return state;
  }
}

export function finishPasswordResetReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FINISH_PASSWORD_RESET_REQUESTED:
      return Object.assign({}, state, {
        message: "FINISH_PASSWORD_RESET_REQUESTED",
      });
    case types.FINISH_PASSWORD_RESET_RECEIVED:

      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "FINISH_PASSWORD_RESET_RECEIVED",
      });
    case types.FINISH_PASSWORD_RESET_FAILED:
      return Object.assign({}, state, {
        message: "FINISH_PASSWORD_RESET_FAILED",
      });
    default:
      return state;
  }}

export function updateBusinessDetailsReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_BUSINESS_DETAILS_REQUESTED:
      return Object.assign({}, state, {
        message: "UPDATE_BUSINESS_DETAILS_REQUESTED",
      });
    case types.UPDATE_BUSINESS_DETAILS_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "UPDATE_BUSINESS_DETAILS_RECEIVED",
      });
    case types.JS_ADD_USER_FAILED:
      return Object.assign({}, state, {
        message: "UPDATE_BUSINESS_DETAILS_FAILED",
      });
    default:
      return state;
  }
}

export function fetchBusinessDetailsByJsAccountIdReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.JS_BUSINESS_DETAILS_BY_ID_REQUESTED:
      return Object.assign({}, state, {
        message: "JS_BUSINESS_DETAILS_BY_ID_REQUESTED",
      });
    case types.JS_BUSINESS_DETAILS_BY_ID_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "JS_BUSINESS_DETAILS_BY_ID_RECEIVED",
      });
    case types.JS_BUSINESS_DETAILS_BY_ID_FAILED:
      return Object.assign({}, state, {
        message: "JS_BUSINESS_DETAILS_BY_ID_FAILED",
      });
    default:
      return state;
  }
}
// storeUpdatedCampaignDataReducer for storing the response we are getting from the storeUpdatedCampaignData action method
export function storeUpdatedCampaignDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_STORE_CAMPAIGN_DETAILS_REQUESTED:
      return Object.assign({}, state, {
        message: "UPDATE_STORE_CAMPAIGN_DETAILS_REQUESTED",
      });
    case types.UPDATE_STORE_CAMPAIGN_DETAILS_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "UPDATE_STORE_CAMPAIGN_DETAILS_RECEIVED",
      });
    case types.UPDATE_STORE_CAMPAIGN_DETAILS_FAILED:
      return Object.assign({}, state, {
        message: "UPDATE_STORE_CAMPAIGN_DETAILS_FAILED",
      });
    default:
      return state;
  }
}

  export function fetchCalloutsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_CALLOUTS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_CALLOUTS_REQUESTED",
        });
      case types.FETCH_CALLOUTS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_CALLOUTS_RECEIVED",
        });
      case types.FETCH_CALLOUTS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_CALLOUTS_FAILED",
        });
      default:
        return state;
    }
  }

  export function generateNewCalloutsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.NEW_GENERATED_CALLOUTS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_CALLOUTS_REQUESTED",
        });
      case types.NEW_GENERATED_CALLOUTS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "NEW_GENERATED_CALLOUTS_RECEIVED",
        });
      case types.NEW_GENERATED_CALLOUTS_FAILED:
        return Object.assign({}, state, {
          message: "NEW_GENERATED_CALLOUTS_FAILED",
        });
      default:
        return state;
    }
  }

  export function saveCalloutsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.SAVE_CALLOUTS_REQUESTED:
        return Object.assign({}, state, {
          message: "SAVE_CALLOUTS_REQUESTED",
        });
      case types.SAVE_CALLOUTS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "SAVE_CALLOUTS_RECEIVED",
        });
      case types.SAVE_CALLOUTS_RECEIVED:
        return Object.assign({}, state, {
          message: "SAVE_CALLOUTS_RECEIVED",
        });
      default:
        return state;
    }
  }

  export function fetchSitelinksReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_SITELINKS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_SITELINKS_REQUESTED",
        });
      case types.FETCH_SITELINKS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_SITELINKS_RECEIVED",
        });
      case types.FETCH_SITELINKS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_SITELINKS_FAILED",
        });
      default:
        return state;
    }
  }

  export function generateNewSitelinksReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.NEW_GENERATED_SITELINKS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_SITELINKS_REQUESTED",
        });
      case types.NEW_GENERATED_SITELINKS_RECEIVED:
        
        let { data } = action;
       return Object.assign({}, state, {
          ...action.data,
          message: "NEW_GENERATED_SITELINKS_RECEIVED",
        });
      case types.NEW_GENERATED_SITELINKS_FAILED:
        return Object.assign({}, state, {
          message: "NEW_GENERATED_SITELINKS_FAILED",
        });
      default:
        return state;
    }
  }

  export function saveSitelinksReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.SAVE_SITELINKS_REQUESTED:
        return Object.assign({}, state, {
          message: "SAVE_SITELINKS_REQUESTED",
        });
      case types.SAVE_SITELINKS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "SAVE_SITELINKS_RECEIVED",
        });
      case types.SAVE_SITELINKS_RECEIVED:
        return Object.assign({}, state, {
          message: "SAVE_SITELINKS_RECEIVED",
        });
      default:
        return state;
    }
  }


  export function fetchCampaignDetailsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_CAMPAIGN_DETAILS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_CAMPAIGN_DETAILS_REQUESTED",
        });
      case types.FETCH_CAMPAIGN_DETAILS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_CAMPAIGN_DETAILS_RECEIVED",
        });
      case types.FETCH_CAMPAIGN_DETAILS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_CAMPAIGN_DETAILS_FAILED",
        });
      default:
        return state;
    }
  }

  export function fetchRsaDetailsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_RESPONSIVE_ADS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_RESPONSIVE_ADS_REQUESTED",
        });
      case types.FETCH_RESPONSIVE_ADS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_RESPONSIVE_ADS_RECEIVED",
        });
      case types.FETCH_RESPONSIVE_ADS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_RESPONSIVE_ADS_FAILED",
        });
      default:
        return state;
    }
  }

  export function saveRsaDetailsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.SAVE_RSA_DATA_REQUESTED:
        return Object.assign({}, state, {
          message: "SAVE_RSA_DATA_REQUESTED",
        });
      case types.SAVE_RSA_DATA_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "SAVE_RSA_DATA_RECEIVED",
        });
      case types.SAVE_RSA_DATA_FAILED:
        return Object.assign({}, state, {
          message: "SAVE_RSA_DATA_FAILED",
        });
      default:
        return state;
    }
  }

  export function generateNewHeadlinesReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.GENERATE_RSA_HEADLINES_REQUESTED:
        return Object.assign({}, state, {
          message: "GENERATE_RSA_HEADLINES_REQUESTED",
        });
      case types.GENERATE_RSA_HEADLINES_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "GENERATE_RSA_HEADLINES_RECEIVED",
        });
      case types.GENERATE_RSA_HEADLINES_FAILED:
        return Object.assign({}, state, {
          message: "GENERATE_RSA_HEADLINES_FAILED",
        });
      default:
        return state;
    }
  }

  export function generateNewDescriptionsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.GENERATE_RSA_DESCRIPTIONS_REQUESTED:
        return Object.assign({}, state, {
          message: "GENERATE_RSA_DESCRIPTIONS_REQUESTED",
        });
      case types.GENERATE_RSA_DESCRIPTIONS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "GENERATE_RSA_DESCRIPTIONS_RECEIVED",
        });
      case types.GENERATE_RSA_DESCRIPTIONS_FAILED:
        return Object.assign({}, state, {
          message: "GENERATE_RSA_DESCRIPTIONS_FAILED",
        });
      default:
        return state;
    }
  }

  export function generateNewPathsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.GENERATE_RSA_PATHS_REQUESTED:
        return Object.assign({}, state, {
          message: "GENERATE_RSA_PATHS_REQUESTED",
        });
      case types.GENERATE_RSA_PATHS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "GENERATE_RSA_PATHS_RECEIVED",
        });
      case types.GENERATE_RSA_PATHS_FAILED:
        return Object.assign({}, state, {
          message: "GENERATE_RSA_PATHS_FAILED",
        });
      default:
        return state;
    }
  }

  export function fetchKeywordsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_KEYWORDS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_KEYWORDS_REQUESTED",
        });
      case types.FETCH_KEYWORDS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_KEYWORDS_RECEIVED",
        });
      case types.FETCH_KEYWORDS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_KEYWORDS_FAILED",
        });
      default:
        return state;
    }
  }

  export function UpdateKeywordsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.UPDATE_KEYWORDS_REQUESTED:
        return Object.assign({}, state, {
          message: "UPDATE_KEYWORDS_REQUESTED",
        });
      case types.UPDATE_KEYWORDS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "UPDATE_KEYWORDS_RECEIVED",
        });
      case types.UPDATE_KEYWORDS_FAILED:
        return Object.assign({}, state, {
          message: "UPDATE_KEYWORDS_FAILED",
        });
      default:
        return state;
    }
  }
  export function fetchCampaignDetailsByJsAccountIdReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.JS_CAMPAIGN_DETAILS_BY_ID_REQUESTED:
        return Object.assign({}, state, {
          message: "JS_CAMPAIGN_DETAILS_BY_ID_REQUESTED",
        });
      case types.JS_CAMPAIGN_DETAILS_BY_ID_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "JS_CAMPAIGN_DETAILS_BY_ID_RECEIVED",
        });
      case types.JS_CAMPAIGN_DETAILS_BY_ID_FAILED:
        return Object.assign({}, state, {
          message: "JS_CAMPAIGN_DETAILS_BY_ID_FAILED",
        });
      default:
        return state;
    }
  }


  export function storeAdgroupDataReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.STORE_ADGROUP_DETAILS_REQUESTED:
        return Object.assign({}, state, {
          message: "STORE_ADGROUP_DETAILS_REQUESTED",
        });
      case types.STORE_ADGROUP_DETAILS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "STORE_ADGROUP_DETAILS_RECEIVED",
        });
      case types.STORE_ADGROUP_DETAILS_FAILED:
        return Object.assign({}, state, {
          message: "STORE_ADGROUP_DETAILS_FAILED",
        });
      default:
        return state;
    }
  }

  export function fetchAdGroupDetailsByCampaignIdReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.JS_ADGROUP_DETAILS_BY_ID_REQUESTED:
        return Object.assign({}, state, {
          message: "JS_ADGROUP_DETAILS_BY_ID_REQUESTED",
        });
      case types.JS_ADGROUP_DETAILS_BY_ID_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "JS_ADGROUP_DETAILS_BY_ID_RECEIVED",
        });
      case types.JS_ADGROUP_DETAILS_BY_ID_FAILED:
        return Object.assign({}, state, {
          message: "JS_ADGROUP_DETAILS_BY_ID_FAILED",
        });
      default:
        return state;
    }
  }
  
  export function fetchAdgroupStructureReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_ADGROUP_STRUCTURE_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_ADGROUP_STRUCTURE_REQUESTED",
        });
      case types.FETCH_ADGROUP_STRUCTURE_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_ADGROUP_STRUCTURE_RECEIVED",
        });
      case types.FETCH_ADGROUP_STRUCTURE_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_ADGROUP_STRUCTURE_FAILED",
        });
      default:
        return state;
    }
  }
  export function deleteAdgroupDataReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_ADGROUP_DETAILS_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_ADGROUP_DETAILS_REQUESTED",
        });
      case types.DELETE_ADGROUP_DETAILS_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_ADGROUP_DETAILS_RECEIVED",
        });
      case types.DELETE_ADGROUP_DETAILS_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_ADGROUP_DETAILS_FAILED",
        });
      default:
        return state;
    }
  }

  export function updateUserDetailsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.JS_UPDATE_USER_REQUESTED:
        return Object.assign({}, state, {
          message: "JS_UPDATE_USER_REQUESTED",
        });
      case types.JS_UPDATE_USER_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "JS_UPDATE_USER_RECEIVED",
        });
      case types.JS_UPDATE_USER_FAILED:
        return Object.assign({}, state, {
          message: "JS_UPDATE_USER_FAILED",
        });
      default:
        return state;
    }
  }

  export function deleteUserReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.JS_DELETE_USER_REQUESTED:
        return Object.assign({}, state, {
          message: "JS_DELETE_USER_REQUESTED",
        });
      case types.JS_DELETE_USER_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "JS_DELETE_USER_RECEIVED",
        });
      case types.JS_DELETE_USER_FAILED:
        return Object.assign({}, state, {
          message: "JS_DELETE_USER_FAILED",
        });
      default:
        return state;
    }
  }

  export function createAccountStructureReducer(
    state = {
      reducer_message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.CREATE_ACCOUNT_STRUCTURE_REQUESTED:
        return Object.assign({}, state, {
          reducer_message: "CREATE_ACCOUNT_STRUCTURE_REQUESTED",
        });
      case types.CREATE_ACCOUNT_STRUCTURE_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          reducer_message: "CREATE_ACCOUNT_STRUCTURE_RECEIVED",
        });
      case types.CREATE_ACCOUNT_STRUCTURE_FAILED:
        return Object.assign({}, state, {
          reducer_message: "CREATE_ACCOUNT_STRUCTURE_FAILED",
        });
      default:
        return state;
    }
  }

  export function fetchSyncCheckIdReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_JS_SYNC_CHECK_ID_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_JS_SYNC_CHECK_ID_REQUESTED",
        });
      case types.CREATE_ACCOUNT_STRUCTURE_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_JS_SYNC_CHECK_ID_RECEIVED",
        });
      case types.FETCH_JS_SYNC_CHECK_ID_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_JS_SYNC_CHECK_ID_FAILED",
        });
      default:
        return state;
    }
  }

  export function submitCampaignFrameworkReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.SUBMIT_CAMPAIGN_FRAMEWORK_REQUESTED:
        return Object.assign({}, state, {
          message: "SUBMIT_CAMPAIGN_FRAMEWORK_REQUESTED",
        });
      case types.SUBMIT_CAMPAIGN_FRAMEWORK_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "SUBMIT_CAMPAIGN_FRAMEWORK_RECEIVED",
        });
      case types.SUBMIT_CAMPAIGN_FRAMEWORK_FAILED:
        return Object.assign({}, state, {
          message: "SUBMIT_CAMPAIGN_FRAMEWORK_FAILED",
        });
      default:
        return state;
    }
  }

  export function submitCampaignStructureReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.SUBMIT_CAMPAIGN_STRUCTURE_REQUESTED:
        return Object.assign({}, state, {
          message: "SUBMIT_CAMPAIGN_STRUCTURE_REQUESTED",
        });
      case types.SUBMIT_CAMPAIGN_STRUCTURE_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "SUBMIT_CAMPAIGN_STRUCTURE_RECEIVED",
        });
      case types.SUBMIT_CAMPAIGN_STRUCTURE_FAILED:
        return Object.assign({}, state, {
          message: "SUBMIT_CAMPAIGN_STRUCTURE_FAILED",
        });
      default:
        return state;
    }
  }


  export function campaignFrameworkDownloadReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DOWNLOAD_CAMPAIGN_FRAMEWORK_REQUESTED:
        return Object.assign({}, state, {
          message: "DOWNLOAD_CAMPAIGN_FRAMEWORK_REQUESTED",
        });
      case types.DOWNLOAD_CAMPAIGN_FRAMEWORK_RECEIVED:
        
        let { status } = action;
        return Object.assign({}, state, {
          status,
          message: "DOWNLOAD_CAMPAIGN_FRAMEWORK_RECEIVED",
        });
      case types.DOWNLOAD_CAMPAIGN_FRAMEWORK_FAILED:
        return Object.assign({}, state, {
          message: "DOWNLOAD_CAMPAIGN_FRAMEWORK_FAILED",
        });
      default:
        return state;
    }
  }
  export function fetchAdGroupDetailsByJsAccountIdTESTReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.JS_ADGROUP_DETAILS_BY_ID_TEST_REQUESTED:
        return Object.assign({}, state, {
          message: "JS_ADGROUP_DETAILS_BY_ID_TEST_REQUESTED",
        });
      case types.JS_ADGROUP_DETAILS_BY_ID_TEST_RECEIVED:
  
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "JS_ADGROUP_DETAILS_BY_ID_TEST_RECEIVED",
        });
      case types.JS_ADGROUP_DETAILS_BY_ID_TEST_FAILED:
        return Object.assign({}, state, {
          message: "JS_ADGROUP_DETAILS_BY_ID_TEST_FAILED",
        });
      default:
        return state;
    }
  }


  export function deleteCampaignStructureReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_CAMPAIGN_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_CAMPAIGN_REQUESTED",
        });
      case types.DELETE_CAMPAIGN_RECEIVED:
        
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_CAMPAIGN_RECEIVED",
        });
      case types.DELETE_CAMPAIGN_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_CAMPAIGN_FAILED",
        });
      default:
        return state;
    }
  }
import React, { Component } from "react";

import { Grid } from "@mui/material";
import {
  CompactCardContent,
  MainSectionCardHeader,
  CustomCard,
} from "../../components/cards";
import CartContainer from "../../components/onDemand/cart-container";
import AutoParagraph from "../../NewInterfacePages/paragraph/autoTaskParagraph";
import AutoPostingListTask from "../../NewInterfacePages/autoPosting/auto-posting-list";
import { Helmet } from 'react-helmet';


function autoPosting() {
  return (
    <div>
      <Helmet>
        <title> LXRGuide - Auto Apply Tasks </title>
        <meta
          name="description"
          content="Task recommendations are applied automatically by LXRGuide. User can manually opt in or out. Previous automatic task recommendations will still be in effect."
        />
      </Helmet>
      <CustomCard>
        {/* <AutoParagraph para={"Enable auto posting of tasks. " +
                "This will show your ad less frequently to the customers in non-performing countries, states." +
                "or other regions. "}/> */}
        <AutoParagraph
          para={
            "Auto apply enables automatic posting of LXRGuide recommendations to your Google Ads account. " +
            "By selecting the check box next to the specified tasks, you agree to participate in implementing tasks automatically." +
            " In enabling auto apply, LXRGuide automatically implements suggestions as necessary, saving you time and increasing the effectiveness of your Google Ads account." +
            " Uncheck any of the tasks you do not wish to update automatically." +
            " Changes can be made at any time." +
            " Click save to update your selections."
          }
        />
        <Grid container>
          <Grid item md={12}>
            <CompactCardContent></CompactCardContent>
          </Grid>
        </Grid>
      </CustomCard>
      <AutoPostingListTask></AutoPostingListTask>
    </div>
  );
}
export default autoPosting;

import React, { Fragment } from "react";
import { Grid, Button, Typography, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import icons from "../icons";
import {
  premierServicePack,
  professionalServicePack,
  starterServicePack,
} from "./view-details-data";

const CounterBtns = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 30,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: "6px 6px",
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.counterIconHover,
    },
  },
}))(Button);
const DisplayBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.counterIcon,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.counterIcon,
    },
  },
}))(Button);
const AddIcon = withStyles((theme) => ({
  root: {
    fontSize: 14,
  },
}))(icons.Add);
const RemoveIcon = withStyles((theme) => ({
  root: {
    fontSize: 14,
  },
}))(icons.Remove);

function Counter(props) {
  let { data, onChange } = props;
  // console.log(" Counter data ==>",data)
  const handleAddCount = () => {
    data.currentCount = data.currentCount + 1;
    onChange(data);
  };

  const handleSubCount = () => {
    data.currentCount = data.currentCount - 1;
    onChange(data);
  };

  return (
    <Fragment>
      {
        data.name != starterServicePack &&
        data.name != professionalServicePack &&
        data.name != premierServicePack &&
          data.name != "Web Development" &&
          data.name != "SEO Service" &&
          data.name != "Custom Product Feed Audit/Fix" &&
          data.name != "Custom Product Feed Audit/Fix 10" ? (
          <Grid container direction={"column-reverse"}>
            {data.currentCount == 0 ? (
              <Grid item>
                <CounterBtns
                  variant="contained"
                  mini
                  aria-label="Add"
                  onClick={handleAddCount}
                >
                  <AddIcon />
                </CounterBtns>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip title={"remove"}>
                  <CounterBtns
                    variant="contained"
                    mini
                    aria-label="remove"
                    onClick={handleSubCount}
                  >
                    <RemoveIcon />
                  </CounterBtns>
                </Tooltip>
                <DisplayBtn mini>{data.currentCount}</DisplayBtn>
                {
                   (data.name == "Starter Service Pack" ||
                  data.name == "Professional Service Pack" ||
                 data.name == "Web Development" ||
                    data.name == "SEO Service" ||
                    data.name == "Custom Product Feed Audit/Fix" ||
                    data.name == "Custom Product Feed Audit/Fix 10" ||
                    data.name == "Premier Service Pack") &&
                    data.currentCount != 0 ? (
                    <Tooltip title={`You can only buy 1 ${data.name} at a time.`}>
                      <span>
                        <CounterBtns
                          variant="contained"
                          mini
                          aria-label="Add"
                          disabled
                        >
                          <AddIcon />
                        </CounterBtns>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Add"}>
                      <CounterBtns
                        variant="contained"
                        mini
                        aria-label="Add"
                        onClick={handleAddCount}
                      >
                        <AddIcon />
                      </CounterBtns>
                    </Tooltip>
                  )
                }
              </Grid>
            )}
          </Grid>
        ) : null}
    </Fragment>
  );
}

export default Counter;

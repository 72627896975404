import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  Drawer,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  JsAddUserLabelTypography,
  JsSubSectionTypography,
} from "../../components/typography.jsx";
import JsAddCampaign from "./jsAddCampaign.jsx";
import { JsOrangeContainedBtn, JsTextAsButton } from "../../components/buttons.jsx";
import JsAddAdgroups from "./jsAddAdgroups.jsx";
import JsCampaignData from "./jsCampaignData.jsx";
import { storeUpdatedCampaignData,fetchCampaignDetailsByJsAccountId, fetchAdGroupDetailsByCampaignId,deleteCampaignStructure } from "../../actions/jumpstartRevamped.js"
import { AccountStatus, Authority } from "./commonComponents/jumpstartEnum.js"
import { event } from "d3";

function JsCampaignDetails(props) {
  const {
    id,
    jwtToken,
    authority,
    fetchJsAccountByIdReducer,
    fetchCampaignDetailsByJsAccountId,
    fetchAdGroupDetailsByCampaignId,
    storeUpdatedCampaignData,
    value1,
    handleChange,
    deleteCampaignStructure,
  } = props


  //Campaign details
  const [currencySymbol, setCurrencySymbol] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [campaignList , setCampaignList] = useState([]);

  //Snackbar for adding, updating and deleting campaign data
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState();

  //selected campaign details for edit
  const [selCampaignId, setSelCampaignId] = useState("");
  const [selCampaignName, setSelcampaignName] = useState("");
  const [selCampaignJsId, setSelCampaignJsId] = useState("");
  const [selCampaignType, setSelCampaignType] = useState("");
  const [selDailyBudget, setSelDailyBudget] = useState("");

  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [accountStatus, setAccountStatus] = useState("");
  const [disableFramework, setDisableFramework] = useState(false);

  const [adgroupData, setAdgroupData] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED"){
      setAccountStatus(fetchJsAccountByIdReducer.status);
      if(fetchJsAccountByIdReducer.status == AccountStatus.CAMPAIGN_FRAMEWORK_IN_REVIEW && (authority == Authority.ANALYST ||authority == Authority.RWF)){
        setDisableFramework(true);
      }
    }
  },[fetchJsAccountByIdReducer])

  //when this component loads it will fetch campaign details
  useEffect(async () => {
      const data = await fetchCampaignDetailsByJsAccountId(jwtToken, id);
      if(data.status == 200){
        const {status, ...campaigns } = data;
        setCampaignList(Object.values(campaigns));
        if(campaigns && Object.keys(campaigns).length > 0){
          const firstCampaign = Object.values(campaigns)[0];
          handleCampaignRowClick(firstCampaign.id, firstCampaign.jsAccountId, firstCampaign.name);
        }
      }
  }, [])

  //to get currency symbol and code from js account table
  useEffect(()=>{
    if(fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" && fetchJsAccountByIdReducer.id == id ){
      setCurrencyCode(fetchJsAccountByIdReducer.currencyCode);
      setCurrencySymbol(fetchJsAccountByIdReducer.currencySymbol);
    }
  },[fetchJsAccountByIdReducer])

  //to close the drawer
  const handleCloseIcon = () => {
    setIsOpen(false);
    setIsEditMode(false);
  };

  //to open the drawer
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsEditMode(false);
    setIsOpen(open);
  };
  //to close the snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
   
  const handleCampaignRowClick = async (
    campaignId,
    campaignJsId,
    campaignName
  ) => {
    if (selCampaignId !== campaignId) {
      setSelCampaignId(campaignId);
      setSelCampaignJsId(campaignJsId);
      setSelcampaignName(campaignName);
      setLoading(true);
      const data = await fetchAdGroupDetailsByCampaignId(jwtToken, campaignId);
      if (data.status == 200) {
        const { status, ...adgroups } = data;
        setAdgroupData(adgroups);
      } else {
        setAdgroupData();
      }
      setLoading(false);
    }
  };

  const deleteCampaignClick = async (campaign) => {
    const data = await deleteCampaignStructure(jwtToken, campaign);
    if (data.status === 200) {
       const updatedCampaignList = campaignList.filter(c => c.id !== campaign.id);
       await setCampaignList(campaignList.filter(c => c.id !== campaign.id));
       updatedCampaignList[0] && handleCampaignRowClick(updatedCampaignList[0].id, updatedCampaignList[0].jsAccountId, updatedCampaignList[0].name);
      handleChange(event,0);
      setSnackbarMsg("Campaign deleted successfully");
      setSnackbarOpen(true);
      setError(false);
    } else {
      setSnackbarMsg("Error in deleting campaign");
      setSnackbarOpen(true);
      setError(true);
    }
  };

  const handleEditCampaign = async (campaignName, campaignType, budget, campaignId) => {
    setSelcampaignName(campaignName);
    setSelCampaignType(campaignType);
    setSelDailyBudget(budget);
    setSelCampaignId(campaignId);
    setIsEditMode(true);
    setIsOpen(true);
  }
  const handleAddOrUpdateCampaign = async (campaignId, campaignName, campaignType, dailyBudget) => {
    const newCampaign = {
      id: campaignId,
      name: campaignName,
      type: campaignType,
      budget: dailyBudget,
      jsAccountId: Number(id),
      deleted: 0,
      completionStatus: false,
    };
    const isUpdate = campaignId !== null;
    const data = await storeUpdatedCampaignData(jwtToken, newCampaign);
  
    if (data.status === 200) {
      const {status, ...campaignData } = data;
      setSnackbarMsg(isUpdate ? "Campaign updated successfully" : "Campaign added successfully");
      setError(false);
      setSnackbarOpen(true);
  
      if (isUpdate) {
        setCampaignList(prevList =>
          prevList.map(obj =>
            obj.id === campaignId ? { ...obj, ...campaignData } : obj
          )
        );
      } else {
        setCampaignList(prevList => [...prevList, campaignData]);
        handleCampaignRowClick(data.id, Number(id), campaignName);
        handleChange(event, Object.keys(campaignList).length);
      }
    } else {
      setSnackbarMsg(isUpdate ? "Error in updating campaign" : "Error in adding campaign");
      setError(true);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Grid container direction="row" style={{ height: "60vh", overflowY: "auto" }}>
        <Grid item xs={6} style={{ borderRight: "1px solid #E6E6E6" }}>
          <div
            style={{
              display: "flex",
              padding: "5% 3% 0 3%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <JsAddUserLabelTypography>
              <span className="numberContainer">1</span>Campaign Name & Budget
            </JsAddUserLabelTypography>
            <JsTextAsButton onClick={toggleDrawer(true)} disabled={disableFramework}>
              <Typography sx={{ color: "#F58120", fontWeight: "500" }}>
                + Add Campaign
              </Typography>
            </JsTextAsButton>
          </div>

          <StyledTabs
            orientation="vertical"
            value={value1}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >

            {Object.keys(campaignList).length > 0  ?
              Object.keys(campaignList).map((key, index) => (
                <AntTab
                  onClick={(e) => {
                    handleCampaignRowClick(campaignList[key].id, campaignList[key].jsAccountId, campaignList[key].name);
                  }}
                  key={index}
                  label={
                    <JsCampaignData
                      campaignId={campaignList[key].id}
                      campaignName={campaignList[key].name}
                      campaignType={campaignList[key].type}
                      currencySymbol={currencySymbol}
                      currencyCode={currencyCode}
                      budget={campaignList[key].budget}
                      value={value1}
                      index={index}
                      handleEditCampaign={handleEditCampaign}
                      authority={authority}
                      disableFramework={disableFramework}
                    />
                  }
                  {...a11yProps(index)}
                />
              )) :
              ""
              }
          </StyledTabs>
        </Grid>
        <Grid item xs={6}>
          {Object.keys(campaignList).length > 0  &&
            <TabPanel
               value={value1} index={value1}> 
              <JsAddAdgroups
                authority={authority}
                disableFramework={disableFramework}
                selCampaignId={selCampaignId}
                jsAccountId={id}
                jwtToken = {jwtToken}
                adgroupList = {adgroupData}
                loading = {loading}
                />
            </TabPanel>
            }
           {/* ))} */}
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
          <Box sx={{ width: 550 }}>
            <JsAddCampaign
              isEditMode ={isEditMode}
              handleCloseIcon={handleCloseIcon}
              selCampaignId={selCampaignId}
              selCampaignName={selCampaignName}
              selCampaignType={selCampaignType}
              selDailyBudget={selDailyBudget}
              handleAddOrUpdateCampaign = {handleAddOrUpdateCampaign}
              jsAccountId = {id}
              heading={isEditMode ? "Edit Campaign" : "+ Add Campaign"}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
              deleteCampaignClick={deleteCampaignClick}
            />
          </Box>
      </Drawer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme, tabcolor }) => ({
    textTransform: "none",
    alignItems: "flex-start",
    display: "contents",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: tabcolor || "#838383",
    textAlign: "left",
    fontFamily: "lato",
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  borderBottom: "1px solid #DAE4ED",
});

const mapStateToProps = (state) => ({
  storeUpdatedCampaignDataReducer: state.storeUpdatedCampaignDataReducer,
  fetchCampaignDetailsByJsAccountIdReducer: state.fetchCampaignDetailsByJsAccountIdReducer,
  storeAdgroupDataReducer: state.storeAdgroupData,
  deleteAdgroupDataReducer: state.deleteAdgroupDataReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
  fetchAdGroupDetailsByJsAccountIdReducer: state.fetchAdGroupDetailsByJsAccountIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  storeUpdatedCampaignData: (jwtToken, updatedCampaignData) => dispatch(storeUpdatedCampaignData(jwtToken, updatedCampaignData)),
  fetchCampaignDetailsByJsAccountId: (jwtToken, jsAccountId) => dispatch(fetchCampaignDetailsByJsAccountId(jwtToken, jsAccountId)),
  fetchAdGroupDetailsByJsAccountId: (jwtToken, jsAccountId) => dispatch(fetchAdGroupDetailsByCampaignId(jwtToken, jsAccountId)),
  deleteCampaignStructure: (jwtToken, campaign) => dispatch(deleteCampaignStructure(jwtToken, campaign)),
  fetchAdGroupDetailsByCampaignId: (jwtToken, id) => dispatch(fetchAdGroupDetailsByCampaignId(jwtToken, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsCampaignDetails);


import React from 'react';
import Overtenkaccountgraded from './OverTenKAccountGraded';
import Freegradertoolsblocks from './freegradertools'
// import MoreToolsForMarketingToolKit from './MoreToolsForMarketingToolKit'
// import Toolkit from './toolkit'
import { Helmet } from 'react-helmet';

function Freegradertools() {
    return (
        <div style={{zoom:"90%"}}>
            <Helmet>
                <title>Free Tools SEO Tools For Your Business | LXRGuide</title>
                <meta name="description"
                    content="Our free tools like the SEO grader will give you a free analysis of your website and valuable information on structure and performance in order to optimize web presence." />
            </Helmet>
            <Overtenkaccountgraded />
            <Freegradertoolsblocks />
            {/* <MoreToolsForMarketingToolKit /> */}
            {/* <Toolkit/> */}
        </div>

    );
}
export default Freegradertools;